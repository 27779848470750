import React from 'react';
import css from './expert-hot-list.module.less';
import {useDataApi} from "../hook";
import { useHistory} from 'react-router-dom';
import classNames from 'classnames';

/** 热点推荐 */
export const ExpertHotList =(props)=>{
    // const { items =[], goDetail} = props;
    const {style} = props;
    const [{ data,isLoading }] = useDataApi({ url: '/api/newsList', method: 'post', data: { pagesize:10, type: 20 ,page:1}, initialData: {} });
    const { push } = useHistory();

    const goDetail = (opt)=>{
        push({pathname:'/expert/info/view/'+opt.id});
    };

    return (
        <div className={ css.card } style={style}>
            <div className={css.title}>
                热点推荐
            </div>
            <div className={css.list}>
                {
                    !isLoading && data.data.map((opt,index)=>
                        <div className={ classNames(css.item) } key={ index } onClick={()=>goDetail(opt)} >
                            {/*<div className={css.index}>{index+1}</div>*/}
                            <div className={ css.name } >{ opt.title }</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}