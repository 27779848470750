import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './notices-scroll.module.less';
import {useHistory} from "react-router-dom";

export const NoticesScroll =(props)=>{

    const {items=[]} = props;
    const [swiper, updateSwiper] = useState(null);
    const {push} = useHistory()
    const params = {
        slidesPerView: 3,
        spaceBetween: 30,
        // loop: true,
        // centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        getSwiper: updateSwiper,
    }

    const handleMouseOver = ()=>{
        swiper.autoplay.stop()
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start()
    };
    const goDetail = (opt)=>{
        console.log("opt",opt);
        push({pathname:'/info/view/'+opt.id})
    }
    return (
        <div className={ css.list } >
            {items.length &&  <Swiper { ...params } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                {
                    items.map((opt,index)=>
                        <div className={ css.item } key={ index } onClick={()=>goDetail(opt)}>
                            <div className={ css.title }>{ opt.title }</div>
                            <div className={ css.time }>{ opt.releasetime }</div>

                        </div>
                    )
                }
            </Swiper>}

        </div>
    );
}