import React, {useEffect, Fragment, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom'
import {useEffectOnce} from 'react-use';
import {Breadcrumb, YcImage, YcPagination} from '../../components';
import {useDataApi} from '../../hook';
import css from './index.module.less';
import Banner from 'aliasimgurl/banner_04.png';
import {Form, Input,Button,Image} from 'antd';
import IconEmpty from 'aliasimgurl/icon_empty_02.png';
import {resourcesUrl} from "../../common/static_number";



export const DatabaseView = (props) => {
    const [ideaForm] = Form.useForm();
    const [{data, isLoading, setData}, updateParams] = useDataApi();
    useEffect(()=>{
        const params = props.location.state||{};
        updateParams({url: '/api/landDetail', method: 'post', data: {...params}})
        ideaForm.setFieldsValue({
            ...params
        })
    }, [props.location.state]);

    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 2);
            }
            ;
        };
        scrollToTop();
    });

    /** 免费查询 */
    const saveIdea = ()=>{
        ideaForm.validateFields().then((params)=>{
            updateParams({url: '/api/landDetail', method: 'post', data: {...params}})
        })
    };

    return (
        <div className="container">
            <div className={css.banner}>
                <YcImage imgUrl={Banner} style={{width: "100%"}}/>
            </div>
            <div className={css.bodyWarp}>
                <div className={css.searchWrap}>
                    <Form form={ideaForm} className={css.form}>
                        <Form.Item
                            label=""
                            name="contractor"
                            style={{width:"350px", marginRight: '20px'}}
                            rules={[{required: true, message: '请输入承包方代表姓名'}]}
                        >
                            <Input autoComplete="off" placeholder="承包方代表姓名"/>
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="ID_card"
                            style={{width:"350px", marginRight: '40px'}}
                            rules={[{required: true, message: '请输入您的身份证号'}]}
                        >
                            <Input autoComplete="off" placeholder="身份证号"/>
                        </Form.Item>
                        <Button className={css.btn} onClick={saveIdea}>免费查询</Button>
                    </Form>
                    <div className={css.hint}>
                        为您查到相关结果{data? 1:0}个
                    </div>
                    {data ? <div className={css.content}>
                        <div className={css.row}>
                            <div className={css.label}>发包方全称：</div>
                            <div className={css.value}>{data.employer}</div>
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>合同代码：</div>
                            <div className={css.value}>{data.contract_code}</div>
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>承包方代表：</div>
                            <div className={css.value}>{data.contractor}</div>
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>身份证号：</div>
                            <div className={css.value}>{data.ID_card}</div>
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>土地面积：</div>
                            <div className={css.value}>{data.measure}（亩）</div>
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>土地权证：</div>
                            {
                                data.certificate && data.certificate.map(
                                    (item,index)=>{
                                        return  <Image
                                            width={200}
                                            src={resourcesUrl + item}
                                        />
                                    }
                                )
                            }
                        </div>
                        <div className={css.row}>
                            <div className={css.label}>土地示意图：</div>
                            {
                                data.soil_map && data.soil_map.map(
                                    (item,index)=>{
                                        return  <Image
                                            width={200}
                                            src={resourcesUrl + item}
                                        />
                                    }
                                )
                            }
                        </div>
                    </div> : <div className={css.emptyData}>
                        <img src={IconEmpty} alt=""/>
                        <div >未找到符合条件的数据</div>
                    </div>}


                </div>

            </div>

        </div>
    )
}
