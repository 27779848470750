import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom'
import {useIsLogin, useUserInfo} from '../../../hook';
import classNames from 'classnames';
import css from './AccountHeader.module.less';
import {ServicesModel} from "../../../common";
import {store} from "../../../helps";
import {message} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import cookie from 'react-cookies';
import avatar from 'aliasimgurl/default_avatar.png';
import logo from 'aliasimgurl/mine-logo.png';
import {PROJECT_URL, TENDER_URL} from "../../../common/static_number";

export const AccountHeader = ()=>{
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();

    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                store.clearAll();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')
                cookie.remove('token', { domain,path: '/' });
                message.success('退出成功！',3, window.location.href = '/');

            },
            error:err=>{},
        });
    }


    return (
        <div className={ css.accountHeaderWrap }>
            <div className={ classNames("container",css.accountHeader) }>
                <a href={'/'} className={ css.logo }>
                    <img src={logo} alt=""/>
                </a>
                <div className={ css.ext }>
                    {
                        isLogin?
                            <Fragment>
                                <a href="/" className={css.goHome}>回到主站</a>
                                <a className={css.userinfo} href={'/account/setting'}>
                                    <img src={data.avatar || avatar} alt=""/> <span>{data.mobile}&nbsp;&nbsp;</span>
                                </a>
                                <div className={css.menu}>
                                    <a href={'/account/home'} className={css.menuTitle} target={"_block"}>个人中心 <CaretDownOutlined /></a>
                                    <div className={css.menuBox}>
                                        <a href={"/account/setting"}>账户设置</a>
                                        <a href={"/account/work"}>消息通知</a>
                                        {/*<a href={"/account/project"}>土地管理</a>*/}
                                        {/*<a href={TENDER_URL + "/account/project"} target={"_block"}>招投标管理</a>*/}
                                        {/*<a href={PROJECT_URL + "/account/project"} target={"_block"}>项目库管理</a>*/}
                                        {/*<a href={"/account/collect"}>收藏夹</a>*/}
                                    </div>
                                </div>
                                <i></i>
                                <a onClick={ handleLogout } className={css.link}>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login" className={css.link}>登录</NavLink>
                                <i></i>
                                <NavLink to="/reg" className={css.link}>注册</NavLink>
                            </Fragment>
                    }
                </div>
            </div>
        </div>

    )
}