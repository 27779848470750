import React from 'react';

import echarts from 'echarts'

/** 阶段统计 */
export class StageCount extends React.Component{
    constructor(props) {
        super(props);
        const {data=[]} = props;
        this.state = {
            data
        }
    }
    componentDidMount(){
        this.loadMap();
    }

     loadMap =()=> {
        const option = {
            title: {
                text: '项目阶段统计',
                subtext: '注释：项目在各阶段的占比情况',
                left: 'left'
            },
            textStyle:{
                fontSize:14
            },
             tooltip: {
                 // trigger: 'item',
                 formatter: '{b} : {c} ({d}%)'
             },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        pixelRatio: 4
                    }
                }
            },
             legend: {
                 orient: 'vertical',
                 right: 200,
                 top: 60,
             },
             series: [
                 {
                     // name: '阶段统计',
                     type: 'pie',
                     radius: '65%',
                     left: '-100px',
                     top: '20px',
                     bottom: '0',

                     // layoutSize: 100,

                     data: this.state.data,
                     emphasis: {
                         itemStyle: {
                             shadowBlur: 10,
                             shadowOffsetX: 0,
                             shadowColor: 'rgba(0, 0, 0, 0.5)'
                         }
                     }
                 }
             ]
         };
        var myChart = echarts.init(document.getElementById('stageCount'));

        myChart.setOption(option);
    }

    render(){
        return (
            <div >
                <div id={'stageCount'}  style={{ width: '100%', height: 446 }} />
            </div>
        );
    }

}