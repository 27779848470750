import React from 'react';
import css from './elite-info-list.module.less';
import banner02 from 'aliasimgurl/banner_02.png'
import {TYPE} from "../../../common/static_number";

/** 项目资讯 */
export const EliteInfoList =(props)=>{

    const {items=[],goDetail} = props;

    return (
        <div className={css.box} >
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} onClick={() => goDetail(opt)}>
                        <img src={opt.images} alt=""/>
                        <div className={css.right}>
                            <div className={css.title}>{opt.title}</div>
                            <div className={ css.footer }>{TYPE[opt.type]}</div>
                        </div>

                    </div>
                )
            }
        </div>
    );

}