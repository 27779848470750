import React from 'react';
import css from './default-list.module.less';
import { useHistory} from 'react-router-dom';

export const DefaultList =(props)=>{
    const { items =[]} = props;
    const {push} = useHistory()
    const goDetail= (id)=>{
        push({pathname:'/info/view/'+id})
    }
    return (
        <div className={ css.list }>
            {
                items.map((opt,index)=>
                    <div className={ css.item } key={ index } onClick={ ()=>{goDetail(opt.id)}}>
                        <div className={ css.title } >{ opt.title }</div>
                    </div>
                )
            }
        </div>
    );
}