import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import {useDataApi} from "../../../hook";

/** 广告服务 */
export const Adve = (props) => {
    // 类型:1=平台介绍,2=法律声明,3=广告服务,4=联系我们
    const [{data,isLoading }] = useDataApi({ url: '/api/introduce', method: 'post',data:{type:3}, initialData: {}});

    return (
        <div className={css.body}>
            <TitleBox title={'广告服务'} />
            {
                !isLoading && <div className={css.content}>
                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                </div>
            }
        </div>
    )
}