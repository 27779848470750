import React from 'react';
import {NavLink ,Link} from 'react-router-dom'
import css from './main-nav.module.less';
import {PROJECT_URL, TENDER_URL} from "../common/static_number";

export const MainNav = (props) => {

    const mainNav = [
        {
            path:`/index`,
            label:'首页',
        },{
            path:`/exchange`,
            label:'土地流转',
        },{
            path:`/assess`,
            label:'土地评估',
        // },{
        //     path:`/design`,
        //     label:'规划设计',
        },{
            path: PROJECT_URL,
            label:'百大项目库',
            target:'_black'
        },{
            path: TENDER_URL,
            label:'招投标',
            target:'_black'
        },{
            path:`/expert`,
            label:'专家智库',
        },{
            path:`/info`,
            label:'土地资讯',
        },{
            path:`/database`,
            label:'数据中心',
        },{
            path:`/about/home`,
            label:'平台介绍',
        }
    ];
    return (
        <div className={ css['main-nav'] }>
            <div className="container">
                <div className={ css.navList }>
                    {
                        mainNav.map((opt,index)=>{
                            return opt.target?  <a href={ opt.path } target={'_blank'} className={ css.nav } key={'nav'+index}>{ opt.label }</a>: <NavLink  key={'nav'+index}
                                    to={ opt.path } className={ css.nav }
                                    activeClassName={ css.selected }>
                                    <div>
                                        <span>{ opt.label }</span>
                                    </div>
                                </NavLink>


                        }

                        )
                    }
                </div>
            </div>
        </div>
    );
}
