import React from 'react';
import {Steps} from 'antd';
import css from './add-project-steps.module.less'
const { Step } = Steps;

export const AddProjectSteps = (props)=>{
    const {current=0} = props;
    return (
        <div style={{display:'flex'}} className={css.steps}>
            <Steps current={Number.parseInt(current)}>
                <Step title="填写土地基本详情" />
                <Step title="平台审核" />
                <Step title="正式发布"/>
            </Steps>
        </div>
    )
}

export const AddAssessSteps = (props)=>{
    const {current=0} = props;
    return (
        <div style={{display:'flex'}} className={css.steps}>
            <Steps labelPlacement={"vertical"}  current={Number.parseInt(current)}>
                <Step title="土地基本信息" />
                <Step title="填写地块信息" />
                <Step title="填写配套设施"/>
                <Step title="填写周边环境"/>
                <Step title="填写经营现状"/>
                <Step title="生成评估报告"/>
            </Steps>
        </div>
    )
}
