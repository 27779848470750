import React, {Fragment} from 'react';
import css from './left-box.module.less';

export const LeftBox =(props)=>{
    const {children} = props;
    return (
        <div className={css.leftBox}>
            <Fragment >
                {children}
            </Fragment>
        </div>
    );

}