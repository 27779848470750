import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb} from '../../../components';
import {useDataApi} from '../../../hook/dataApi';
import css from './list.module.less';
import {List} from "./../components";
import classNames from 'classnames';
import expertBanner from 'aliasimgurl/banner_expert.png'
import {ExpertHotList} from "../../../components/expert-hot-list";
import {DownOutlined} from '@ant-design/icons';


export const ExpertInfoList = (props) => {
    let { push } = useHistory();
    const {type=7} = props.location.state || {};

    const [searchValue, setSearch] = useState({page:1,pagesize:10,type});
    const [page,setPage] = useState(1);
    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});
        }
        scrollToTop();
    },[props.location.state]);
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [ { data, setData },updateParams ]  =useDataApi({});
    const [ { data:nextPage,isLoading },getNextPage ]  =useDataApi({});

    useEffect(() => {
        updateParams({url:'/api/newsList',method:'post',data:{ ...searchValue}});
    }, [searchValue]);
    useEffect(() => {
        if(page !== 1){
            getNextPage({url:'/api/newsList',method:'post',data:{ ...searchValue,...{page}}});
        }
    }, [page]);
    useEffect(() => {
        if(!isLoading){
            let _data = data;
            _data.data = _data.data.concat(nextPage.data);
            setData({..._data});
        }
    }, [nextPage,isLoading]);
    function toView(opt) {
        const {id=''} = opt;
        push({pathname:'/expert/info/view/'+id})
    }
    function handleNextPage (){
        setPage(page+1);
    }
    return (
        <div>
            <div className="container">
                <Breadcrumb list={ [{name:'专家智库',path:'/expert'},{name:'专家资讯'}] } />
            </div>
        <div style={{background: '#fff',padding: '20px 0'}}>
            <div className="container">
                <div className={css.bodyWarp}>
                    <div className={css.leftWarp}>
                        <div className={ css.listWrap }>
                            {/*//1=森林康养,2=空间规划,3=产业策划,4=景观设计,5=特色小镇*/}
                            <div className={css.tabs}>
                                <div className={classNames(css.tab,searchValue.type === 7 ? css.active:'')} onClick={()=>updateValue({type: 7})} >
                                    专家访谈
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.type === 8 ? css.active:'')} onClick={()=>updateValue({type: 8})}>
                                    专家动态
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.type === 9 ? css.active:'')} onClick={()=>updateValue({type: 9})}>
                                    专家文献
                                    <div className={css.border} />
                                </div>
                                <div className={classNames(css.tab,searchValue.type === 10 ? css.active:'')} onClick={()=>updateValue({type: 10})}>
                                    专家观点
                                    <div className={css.border} />
                                </div>
                            </div>
                            <List itemsLength={ 16 } data={ data.data || [] } count={data.count} toView={ toView } />
                            {page*searchValue.pagesize <= data.count && <div className={css.more} >
                                <span onClick={handleNextPage}>加载更多&nbsp;<DownOutlined style={{fontSize : '10px'}}/></span>
                            </div>}
                        </div>
                    </div>
                    <div className={css.rightWarp}>
                        <div className={css.bannerWarp} onClick={()=>push({pathname:'/addproject',state:{}})}>
                            <img src={expertBanner} alt=""/>
                        </div>
                        <ExpertHotList style={{marginTop:'13px'}}/>

                    </div>
                </div>

            </div>
        </div>
        </div>
    );
}