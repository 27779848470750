import React,{useState} from 'react';
import css from './search-box.module.less';
import {Form,Input,Button,Select,Modal} from "antd";
import icon_01 from 'aliasimgurl/icon_01.png';
import icon_01_active from 'aliasimgurl/icon_01_active.png';
import icon_02 from 'aliasimgurl/icon_02.png';
import icon_02_active from 'aliasimgurl/icon_02_active.png';
import icon_03 from 'aliasimgurl/icon_03.png';
import icon_03_active from 'aliasimgurl/icon_03_active.png';
import {ServicesModel} from "../../../common";
import {useDataApi, useIsLogin} from "../../../hook";
import {FOREST_TYPE,FOREST_AREA,FORESTRY_TYPE} from "../../../common/static_number";
import * as R from 'ramda';
import {useHistory} from "react-router-dom";

const layout = {
    labelCol:{
        span: 8
    }
};
const {Option} = Select;
/** 精品项目 */
export const SearchBox =(props)=>{
    const [tab,setTab] = useState(1);
    const {push} = useHistory();
    const  [isLogin] = useIsLogin();
    //     项目名称                                 采购产品                     需求数量                         土地地区                     报名截止日期
    const [{ ...areaData }] = useDataApi({url:'/api/getCity',method: 'post',data:{pid:0,type:2},initialData: []});
    const isEmpty = n=> { return !!n};
    /** 发布土地 */
    const sendForest = (v)=>{
        if(isLogin){
            push({pathname:'/addproject',state:{...R.filter(isEmpty,v)}})
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }
    };
    /** 寻找土地 */
    const searchForest = (v)=>{

        push({pathname:'/exchange',state:{...R.filter(isEmpty,v)}})
    };
    /** 土地评估 */
    const assessForest = (v)=>{
        if(isLogin){
            push({pathname:'/addAssess',state:{...R.filter(isEmpty,v)}})
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }
    };
    return (
        <div className={css.box}>
            <div className={css.tabs}>
                <div className={tab === 1? css.active:''} onMouseOver={()=>setTab(1)}>
                    <img src={tab === 1? icon_01_active:icon_01} alt=""/>
                    <div>发布土地</div>
                </div>
                <div className={tab === 2? css.active:''} onMouseOver={()=>setTab(2)}>
                    <img src={tab === 2? icon_02_active:icon_02} alt=""/>
                    <div>寻找土地</div>
                </div>
                <div className={tab === 3? css.active:''} onMouseOver={()=>setTab(3)}>
                    <img src={tab === 3? icon_03_active:icon_03} alt=""/>
                    <div>土地评估</div>
                </div>
            </div>
            <div className={css.content}>
                { tab === 1 &&
                <Form onFinish={sendForest} {...layout}>
                    <div className={css.title}>填写信息类型，帮您快速找到土地</div>
                    <Form.Item label='流转类别' name={'forestry_type'} >
                        <Select style={{width:'100%'}}
                                placeholder="请选择流转类别"
                        >
                            {
                                [...FORESTRY_TYPE].map(opt=>
                                    <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='土地地区' name={'province_id'} >
                        <Select style={{width:'100%'}}
                                placeholder="请选择土地地区"
                        >
                            {
                                [...areaData.data].map(opt=>
                                    <Option  key={ opt.id } value={ opt.id }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='土地类型' name={'type'}>
                        <Select style={{width:'100%'}}
                                placeholder="请选择土地类型"
                                value={ void 0 }
                        >
                            <Option value=''>不限</Option>
                        {
                            FOREST_TYPE.map(opt=>
                                <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                            )
                        }
                        </Select>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button htmlType={'submit'} className={css.submitBtn}>免费发布土地信息</Button>
                    </div>
                </Form>
                }
                { tab === 2 &&
                <Form onFinish={searchForest} {...layout}>
                    <div className={css.title}>填写信息类型，帮您快速找到土地</div>

                    <Form.Item label='土地地区' name={'province_id'} >
                        <Select style={{width:'100%'}}
                                placeholder="请选择土地地区"
                                value={ void 0 }
                        >
                            {
                                [...areaData.data].map(opt=>
                                    <Option  key={ opt.id } value={ opt.id }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='土地类型' name={'type'} >
                        <Select style={{width:'100%'}}
                                placeholder="请选择土地类型"
                                value={ void 0 }
                        >
                            {
                                FOREST_TYPE.map(opt=>
                                    <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='土地面积' name={'measure_area'}>
                        <Select style={{width:'100%'}}
                                placeholder="请选择土地面积"
                                value={ void 0 }
                        >
                            {
                                FOREST_AREA.map(opt=>
                                    <Option  key={ opt.value } value={ opt.value }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button htmlType={'submit'} className={css.submitBtn}>开始找土地</Button>
                    </div>
                </Form>
                }
                { tab === 3 &&
                <Form onFinish={assessForest} {...layout}>
                    <div className={css.title}>填写土地信息，帮您快速评估</div>
                    <Form.Item label='土地地区' name={'province_id'} >
                        <Select style={{width:'100%'}}
                                placeholder="请选择"
                                value={ void 0 }
                             >
                            {
                                [...areaData.data].map(opt=>
                                    <Option  key={ opt.id } value={ opt.id }> { opt.name }</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='使用年限'   name={'years'} >
                        <Input type="text" placeholder={'请输入剩余年限'} autoComplete="off"  suffix={'年'}/>
                    </Form.Item>
                    <Form.Item label='土地面积' name={'area'}>
                        <Input type="text" placeholder={"请输入土地面积"} autoComplete="off" suffix={'亩'}/>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button htmlType={'submit'} className={css.submitBtn}>免费评估</Button>
                    </div>
                </Form>
                }
            </div>

        </div>
    );

}
