import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {AddressItem, YcImage, YcPagination, YcRadio} from '../../components';
import {List} from './components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import Banner from 'aliasimgurl/banner_04.png';
import {Form, Input,Button,Modal} from 'antd';
import {useIsLogin} from "../../hook";
import {resourcesUrl} from "../../common/static_number";



export const Database = (props) => {
    let { push   } = useHistory();
    const [isLogin] = useIsLogin();
    const [ideaForm] = Form.useForm();

    scrollToTop();
    const [{data:banner,isLoading:bannerLoading}] = useDataApi({ url: '/api/dataBanner', method: 'post',initialData:[]});
    const [{data,isLoading,setData},updateParams] = useDataApi({url:'/api/dataindex',method: 'post',data:{}});
    function toView(opt) {
        const {id} = opt;
        push({pathname:'/database/info/'+id})
    }
    console.log("banner",banner);
    console.log("bannerLoading",bannerLoading);
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    /** 免费查询 */
    const saveIdea = ()=>{
        if(!isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
        }else{
            ideaForm.validateFields().then((params)=>{
                console.log("params",params);
                push({pathname:'/database/view/',state:{...params}})
            })
        }

    };
    const moreHandle = ()=>{
        push({pathname:'/database/list'})
    };
    return (
        <div style={{background: '#fff'}}>
            <div className="container" >
                <div className={css.banner}>
                    {

                        !bannerLoading && <YcImage imgUrl={ resourcesUrl+ banner[0].images } style={{width: "100%"}}/>
                    }
                </div>
                <div className={css.bodyWarp}>
                    <div className={ css.searchWrap }>
                        <Form form={ideaForm} className={css.form}>
                            <Form.Item
                                label=""
                                name="contractor"
                                style={{width:"350px", marginRight: '20px'}}
                                rules={[{required: true, message: '请输入承包方代表姓名' }]}
                            >
                                <Input autoComplete="off" placeholder="承包方代表姓名"/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="ID_card"
                                style={{width:"350px", marginRight: '40px'}}
                                rules={[{required: true, message: '请输入您的身份证号' }]}
                            >
                                <Input autoComplete="off" placeholder="身份证号"/>
                            </Form.Item>
                            <Button className={css.btn} onClick={saveIdea}>免费查询</Button>
                        </Form>
                    </div>
                    <div className={ css.listWrap }>
                        <div className={ css.title }>数据动态</div>
                        {!isLoading && <List itemsLength={ 16 } data={ data || [] } toView={ toView } />}
                        <div className={css.more}><span onClick={moreHandle}>查看更多>></span></div>

                    </div>
                </div>

            </div>
        </div>

    );
}
