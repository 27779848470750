import config from "./config";
import {aesDecrypt, aesEncrypt, store} from '../helps';
import {tap} from "rxjs/operators";
import cookie from 'react-cookies';

export class ServicesModel {

    static getUserInfo(data){
        return config.getUserInfo(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('userinfo',aesEncrypt(data.userinfo),expires);
                    store.set('isLogin',true, Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    cookie.save('token', data.userinfo.token,{expires,domain,path:'/'});

                };
            }),
        );
    }

    static doLogin(data){
        return config.doLogin(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('userinfo',aesEncrypt(data.userinfo), Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    store.set('isLogin',true, Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    cookie.save('token', data.userinfo.token,{expires,domain,path:'/'});
                };
            }),
        );
    }

    static doLogout(data){
        return config.doLogout(data).pipe(
            tap(res=>{
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')

                store.clearAll();
                cookie.remove('token',{domain,path:'/'});
            }),
        );
    }

    // doRegister
    static doRegister(data){
        return config.doRegister(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain :  "." + document.domain.split('.').slice(-2).join('.')

                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                if (data && data.userinfo && data.userinfo.token) {
                    store.set('isLogin',true,Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    store.set('userinfo',aesEncrypt(data.userinfo),Date.now() + 1000 * 60 * 60 * 24 * 12);// 设置过期时间
                    cookie.save('token',data.userinfo.token,{expires,domain,path:'/'});
                };
            }),
        );
    }

    static doChangeMobile(data){
        return config.doChangeMobile(data).pipe(
            tap(res=>{
                store.remove('userinfo');
            })
        );
    }

    static doResetpwd(data){
        return config.doResetpwd(data);
    }

    static getSendCode(data) {
        return config.getSendCode(data);
    }

    /** 验证手机号是否存在 */
    static verifyMobile(data) {
        return config.verifyMobile(data);
    }

    static getSigns(data){
        return config.getSigns(data).pipe(
            tap(({data})=>{
                const expires = new Date();
                const domain = document.domain === 'localhost' ? document.domain :  "." + document.domain.split('.').slice(-2).join('.')
                expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 12);

                if (data && data.token) {
                    cookie.save('token',data.token,{expires,domain,path:'/'});

                }
            })
        );
    }

    static getCityData(data){
        return config.getCityData(data);
    }

    /** 身份认证 */
    static authForm(data){
        return config.authForm(data);
    }
    static doRetrievepwd(data){
        return config.doRetrievepwd(data);
    }
    static applyMember (){
        return config.applyMember();
    }
    /** 保存意见 */
    static addProposal(data){
        return config.addProposal(data);
    }
    /** 更新头像 */
    static updateAvatar(data){
        return config.updateAvatar(data);
    }
}

export class PublishModal {
    /** 更新消息状态  */
    static updateNotice(data){
        return config.updateNotice(data);
    }

    /** 更新系统消息状态  */
    static resetMessage(data){
        return config.resetMessage(data);
    }
}

/** 上传文件相关接口  */
export class UploadConfig {
    /** 创建项目 */
    static fetchUploadToken(data){
        return config.fetchUploadToken(data);
    }
}

/** 项目信息接口  */
export class ProModal {

    /** 创建项目 */
    static addPro(data){
        return config.addPro(data);
    }
    /** 项目评估 */
    static assessment(data) {
        return config.assessment(data);
    }
        /** 收藏 */
    static collect(data){
        return config.collect(data);
    }
    /** 取消收藏 */
    static cancelCollect(data){
        return config.cancelCollect(data);
    }

    /** 增加下载数量 */
    static downloadAdd(data){
        return config.downloadAdd(data);
    }

}

