import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDataApi, useUserInfo} from '../../../hook';
import css from './index.module.less';
import classNames from 'classnames';

import {PublishModal} from "../../../common";
import iconVip from "aliasimgurl/icon_mine_vip.png";
import iconManager from "aliasimgurl/icon_manager.png";
import iconAuth from "aliasimgurl/icon_mine_auth.png";
import app01 from "aliasimgurl/app_01.png";
import app02 from "aliasimgurl/app_02.png";
import app03 from "aliasimgurl/app_03.png";
import app04 from "aliasimgurl/app_04.png";
import iconMineMessage from "aliasimgurl/icon_mine_message.png";
import {EXPERT_URL, PROJECT_URL, TENDER_URL} from "../../../common/static_number";
import {EmptyData} from "../../../components/empty-data";

/** 用户主页 */
export const Home = ()=>{
    const [dataList, setDataList] = useState([]);
    const [params, setParams] = useState({});
    const [userInfo] = useUserInfo();
    const [{data: dataSource, isLoading},updateParams] = useDataApi();
    useEffect(() => {
        if (!isLoading) {
            const {data = [], total} = dataSource;
            const _params = params;
            _params.total = total;
            setDataList(data);
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {page=1} = params;
        updateParams({
            url: '/api/user/NoticeList',
            method: 'post',
            data: {page, category:9}
        });
    }, [params]);
    const {push} = useHistory();
    const goDetail = ({project_id,id,category,type,status}) => {

        const callback = ()=>{
            skip({project_id});
        }
        markRead({category,type,status,id,callback});
    };
    const markRead = ({category,type,status,id,callback=()=>{}},index=null)=>{
        if(!status){
            PublishModal.updateNotice({project_notice_id:id}).pipe().subscribe({
                next:res=>{
                    callback();
                    if(index !== null){
                        const _dataList = dataList;
                        _dataList[index].status = 1;
                        setDataList(_dataList);
                    }
                },
                error:err=>{},
                complete:()=>{}
            });
        }else{
            callback();
        }
    };

    const skip = ({project_id})=>{
        push({pathname: '/account/project/list',state:{project_sn:project_id}});
    };

    const goPage = (type)=>{
        switch (type) {
            case 'forest': //土地发布
                push({pathname:'/addproject',state:{}});
                break;
            case 'bid': //招采发布
                window.open(TENDER_URL+'/publish');
                break;
            case 'product': //项目入库
                window.open(PROJECT_URL+'/addproject');
                break;
            case 'expert': //专家智库
                window.open(EXPERT_URL);
                break;
            default:
        }
    };
    return (
        <div className={css.box}>
            <div className={css.header}>
                <img src={iconMineMessage} alt=""/>
                您已成功登录到昆明农村产权流转服务平台 ，快速进入我参与的 <a href={TENDER_URL + '/market'} target={"_blank"}>[招标项目]</a>， 寻找更多土地信息请点击 <a href="/exchange">[查看土地信息]</a>
            </div>
            <div className={css.tabs}>

                <div className={css.tab}>
                    <div className={css.tabHeader}>
                        <img src={iconVip} alt=""/>
                        <span>会员身份</span>
                    </div>
                    {userInfo.is_member == 1 ? <div className={css.other}>
                            <div>会员类型：全平台会员</div>
                            <div>会员级别：金牌会员</div>
                            <div>
                                开通日期：{userInfo.expiretime}
                                <a className={css.keep}  href={'/vip'}>立即续费></a>
                            </div>
                        </div>:<div className={css.other}>
                                <div>会员类型：普通用户</div>
                                <div>会员级别：普通会员</div>

                                {/*<div>*/}
                                    {/*开通日期：<span style={{color: "#888"}}>未开通</span>*/}
                                    {/*<a className={css.auth}  href={'/vip'} >立即开通></a>*/}
                                {/*</div>*/}

                            </div>
                    }

                </div>
                <div className={css.tab}>
                    <div className={css.tabHeader}>
                        <img src={iconAuth} alt=""/>
                        <span>资质认证</span>
                    </div>
                    {userInfo.apply_state == 2 ?
                    <div className={css.other}>
                        {
                            userInfo.type == 1 &&<div className={'line1'}>认证单位：{userInfo.store_name}</div>
                        }
                        <div>认证状态：{userInfo.type == 2 ? "个人": "企业"}</div>
                        <div>
                            认证时间: {userInfo.createtime}
                            <a className={css.keep} href={'/account/setting/information'}>立即查看></a>
                        </div>
                    </div>:  <div className={css.other}>
                            {
                                userInfo.type == 1 &&<div>认证单位：{userInfo.store_name}</div>
                            }
                            <div>认证状态：<span style={{color: "#888"}}>未认证</span></div>
                            <div>
                                认证时间：<span style={{color: "#888"}}>暂无</span>
                                { userInfo.apply_state != 0 ?  <a className={css.keep} href={'/account/setting/userauth/detail'}>立即查看></a>:
                                    <a className={css.open} href={'/account/setting/userauth/auth'}>立即认证></a>}

                            </div>
                        </div>
                    }
                </div>
                <div className={css.tab}>
                    <div className={css.tabHeader}>
                        <img src={iconManager} alt=""/>
                        <span>客户服务</span>
                    </div>

                    <div className={css.other}>
                        {/*<div>客户经理：暂无指定客户经理</div>*/}
                        <div>客服热线： 0871-65819655</div>
                        <div>电子邮箱： 2107385515@qq.com</div>
                    </div>

                </div>
            </div>
            <div className={css.tabTitle}>
                我的应用
            </div>
            <div className={css.apps}>
                <div className={css.app} onClick={()=>goPage('forest')}>
                    <img src={app01} alt=""/>
                    <span>土地发布</span>
                </div>
                <div className={css.app} onClick={()=>goPage('bid')} >
                    <img src={app02} alt=""/>
                    <span>招采发布</span>
                </div>
                <div className={css.app} onClick={()=>goPage('product')}>
                    <img src={app03} alt=""/>
                    <span>项目入库申请</span>
                </div>
                <div className={css.app} onClick={()=>goPage('expert')}>
                    <img src={app04} alt=""/>
                    <span>专家智库申请</span>
                </div>
            </div>
                <div className={css.tabTitle}>
                    最新通知
                </div>
                <div className={css.info}>

                    <div className={css.list}>


                    {
                        !isLoading &&  dataList.length ?  dataList.map((opt,index) =>
                            <div key={opt.id} className={css.row}>
                                <div className={classNames(css.title,'line1')} onClick={()=>goDetail(opt)}>{opt.content}</div>
                                <div className={css.time}>{opt.createtime}</div>
                            </div>
                        ): <EmptyData />
                    }
                    </div>
                </div>
        </div>
    )
}
