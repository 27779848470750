import React from 'react';
import css from './new-item.module.less';
import defaultImg from 'aliasimgurl/default_img.png'
import {useHistory} from "react-router-dom";
import {resourcesUrl} from "../../../common/static_number";
import {EmptyData} from "../../../components/empty-data";


export const NewItem =(props)=>{
    const {data={}} = props;
    const {push} = useHistory();
    const goDetail = (opt)=>{
        push({pathname:"/design/view/"+opt.id});
    }
    return (
        <div className={css.list}>
            {data.length> 0 ? data.map(opt=><div className={ css.item } key={opt.id} onClick={()=>goDetail(opt)}>
                <img src={opt.images[0] ? resourcesUrl +  opt.images[0] : defaultImg} alt=""/>
                <div className={css.box}>
                    <div className={ css.title }>{ opt.title }</div>
                </div>
            </div>): <EmptyData />}

        </div>

    );
}