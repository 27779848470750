import React, {useEffect,Fragment,useState,useRef} from 'react';
import {useHistory} from 'react-router-dom'
import {useEffectOnce} from 'react-use';
import {Breadcrumb, YcImage} from '../../components';
import {message,Modal ,Form,Button,Input} from 'antd';
import {useDataApi} from '../../hook';
import css from './index.module.less';
import {ProModal, ServicesModel} from "../../common";
import {tap} from "rxjs/operators";
import classnames from 'classnames';
import BannerExchange from 'aliasimgurl/banner_exchange.jpg';
import {createFromIconfontCN} from '@ant-design/icons';
import {ExchangeSwiper} from "./components";
import {FOREST_TYPE, getForestryType, resourcesUrl} from "../../common/static_number";
import defaultImg from 'aliasimgurl/default_img.png'
import {Score} from "../assess/components/score";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_rli4q8kct8f.js',
});

const modalCol = {
    labelCol: {span: 6},
    // wrapperCol: {span: 14},
};

export const ExchangeView = (props) => {
    const {id=''} =  props.match.params? props.match.params :{};
    const [form] = Form.useForm();
    const [countdown, showCountdown] = useState(false);
    const [count, setCount] = useState(60);
    const [appointFlag, setAppointFlag] = useState(false);
    const [{data,isLoading,setData},updateParams] = useDataApi();
    const [current ,setCurrent] = useState('detail')
    const {push} = useHistory();
    const detailRef = useRef();
    const imagesRef = useRef();
    const evalRef = useRef();
    const observer = useRef();
    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 2);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
      if(id){
          updateParams({url: '/api/forestryDetail', method: 'post', data: {id}})
      }
    },[id]);
    useEffect(()=>{
        if(!isLoading){
            initObserver();
        }
    },[isLoading]);

    const initObserver = ()=>{
        const idList = ['detail','images','eval'];
        const Observer  = new IntersectionObserver(entries =>{
            entries.forEach(elem =>{
                if(elem.boundingClientRect.y< 0 ){

                    if(Math.abs(elem.boundingClientRect.y) +60 < elem.boundingClientRect.height){
                        // console.log("向上",elem);
                        setCurrent(elem.target.id);
                    }else{
                        // console.log("向下",elem);
                        setCurrent(idList[idList.indexOf(elem.target.id)+1]);
                    }
                }
            })
        },{threshold: [0],rootMargin:'-60px 0px 60px 0px'});
        Observer.observe(detailRef.current);
        Observer.observe(imagesRef.current);
        Observer.observe(evalRef.current);
        observer.current = Observer;
    }

    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            // 找到锚点
            let x = '';
            switch (anchorName) {
                case 'detail':
                    x = detailRef;
                    break;
                case 'images':
                    x = imagesRef;
                    break;
                case 'eval':
                    x = evalRef;
                    break;
                default:;
            }
            window.scrollTo(0,x.current.offsetTop + 400);
            setCurrent(anchorName);

        }
    };

    /** 收藏 */
    const handleCollect = ()=>{
        ProModal.collect({id,type:8}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        ProModal.cancelCollect({id,type:8}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };


    const handleAuditOk = e => {
        console.log("e",e);
        ServicesModel.addProposal({f_id:data.id,type:3,...e}).subscribe({
            next:res=>{
                message.success('提交成功',1,()=>{
                    setAppointFlag(false);
                })
            }
        })
    };
    /** 获取手机验证码 */
    const getCode = () => {
        startCountdown();
        const mobile = form.getFieldValue('mobile');
        ServicesModel.getSendCode({mobile, event: 'proposal '}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:(res)=>{
                message.success('验证码已发送')
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);
    };

    return (
        <div className="container">
            <div className={css.banner}>
                <YcImage imgUrl={ BannerExchange }/>
                <div className={css.btn} onClick={()=>push({pathname:'/addproject',state:{}})}>前往发布土地信息</div>
            </div>
            {!isLoading &&
                <Fragment>
                    <Breadcrumb list={ [{name:'土地流转',path:'/exchange'},{name:data.project_name}] } />
                    <div className={css.viewWrap}>
                        <div className={css.header} >
                            <div className={css.left}>
                                {data.images.length > 0 && <ExchangeSwiper banners={data.images} />}
                            </div>
                            <div className={css.right}>
                                <div className={ css.titleInfo }>
                                    <h2 className={ css.viewTitle }>{data.project_name}</h2>
                                    <p className={css.subTitle}>
                                        <span className={css.text}>发布时间：{data.releasetime}</span>
                                        <span className={css.text}>点击量：{data.read_num}</span>
                                        {
                                            data.is_collect?  <span className={css.check} style={{cursor: "pointer"}} onClick={handleCancelCollect} >
                                                <IconFont type={'icon-collection-b'}  style={{fontSize:'16px'}}/>
                                                <span className={css.text} >已收藏</span>
                                            </span>:<span  onClick={handleCollect} style={{cursor: "pointer"}}>
                                               <IconFont  type={'icon-shoucang1'} style={{fontSize:'16px'}}/><span className={css.text}>收藏</span>
                                            </span>
                                        }
                                    </p>
                                    <div className={css.price}>
                                        预期总价：<span>{data.forestry_price === 0 ?  '面议':data.forestry_price + '万元'}</span>
                                    </div>
                                    <div className={css.ext}>
                                        <p>
                                            土地面积：<span>{data.measure_area}亩</span>
                                        </p>
                                        <p>
                                            流转类别：<span>{getForestryType(data.forestry_type).name }</span>
                                        </p>
                                        <p>
                                            使用年限：<span>{ data.years }年</span>
                                        </p>
                                        <p>
                                            土地类型：<span>{FOREST_TYPE.find(item=> item.value === data.type).name }</span>
                                        </p>
                                        <p>
                                            土地权属：<span>{{1:'个人',2:'集体',3: '企业'}[data.ownership]}</span>
                                        </p>
                                        <p>
                                            证件情况：<span>{{1:'土地证',2:'租赁合同',3: '承包合同',4:'流转合同'}[data.warrant]}</span>
                                        </p>
                                        <p style={{'width': "100%"}}>
                                            土地地区：<span>{data.pname}-{data.cname}-{data.tname}</span>
                                        </p>
                                    </div>
                                    <div className={css.btns}>
                                        <a href="tencent://message/?uin=2107385515"  className={css.greenBtn}>
                                            在线咨询
                                        </a>
                                        <div className={css.yellowBtn} onClick={()=>setAppointFlag(true)}>预约回电</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css.tabs}>
                            <div className={classnames(css.tab,current === 'detail' ? css.active:'')} onClick={()=>scrollToAnchor('detail')}>土地详情</div>
                            <div className={classnames(css.tab,current === 'images' ? css.active:'')} onClick={()=>scrollToAnchor('images')}>土地图片</div>
                            <div className={classnames(css.tab,current === 'eval' ? css.active:'')} onClick={()=>scrollToAnchor('eval')}>土地评测</div>
                        </div>
                        <div className={css.content}>
                            <div  id={'detail'} ref={detailRef}>
                                <div className={css.title} >一、土地详细情况</div>
                                <div className={css.detail}>
                                    {data.content}
                                </div>
                            </div>
                            <div id={'images'} ref={imagesRef}>
                                <div className={css.title}>二、土地图片</div>
                                <div className={css.images}>
                                    { data.images.length > 0 && data.images.map((opt)=> <img key={opt.uid} src={opt.file  ? resourcesUrl + opt.file : defaultImg} alt=""/>)}
                                </div>
                                <div className={css.remark}>注：以上图片仅供参考，请以实地为主。</div>
                            </div>
                            <div   id={'eval'} ref={evalRef}>
                                <div className={css.title} >三、土地评测</div>
                                <div className={css.eval}>
                                    {data.fraction ? <Fragment>
                                        <div className={css.title}>土地综合评估得分：<span>{data.fraction.comprehensive}</span></div>
                                        <Score data={data.fraction}/>
                                        <div className={css.footer}>注：该土地测评由平台大数据进行测评，评估结果基于土地大数据及全网区域特性，因土地受自然因素影响较大，该测评结果仅供参考。</div>
                                    </Fragment> : <Fragment>
                                        <div className={css.title}>该项目暂无评估数据</div>

                                    </Fragment>   }

                                   </div>
                            </div>
                        </div>
                        <Modal title={'预约回电'}
                               visible={appointFlag}
                               footer={null}
                               onCancel={()=>setAppointFlag(false)}
                               width={508}
                               wrapClassName={css.modal}
                        >
                            <div className={css.modalHeader}>请留下您的联系方式，我们会及时联系您。</div>
                            <Form form={form} {...modalCol} onFinish={handleAuditOk}>

                                <Form.Item name={'name'} rules={[{required: true, message: '请输入姓名'}]}>
                                    <Input addonBefore={'姓名：'} placeholder={'请输入姓名'} size={"large"}/>
                                </Form.Item>
                                <Form.Item name={'mobile'} rules={[{required: true, message: '请输入手机号码'}]}>
                                    <Input addonBefore={'手机号码：'} maxLength={11} placeholder={'请输入手机号码'} size={"large"}/>
                                </Form.Item>
                                <Form.Item style={{marginBottom: '0'}} required>
                                    <Form.Item
                                        name={'code'}
                                        wrapperCol={{span: 23}}
                                        style={{width:'296px' ,display: 'inline-block'}}
                                        rules={[{required: true, message: '请输入手机验证码'}]}
                                    >
                                        <Input  addonBefore={'手机验证:'} placeholder='手机验证码' autoComplete="off" size={"large"}/>
                                    </Form.Item>
                                    {
                                        !countdown ?
                                            <Form.Item style={{display: 'inline-block'}}>
                                                <Button type="primary" onClick={() => {
                                                    getCode();
                                                }} size={"large"} ghost>发送短信验证码</Button>
                                            </Form.Item> :
                                            <Form.Item style={{display: 'inline-block'}}>
                                                <Button type={"primary"} disabled size={"large"}>{count}s后获取</Button>
                                            </Form.Item>
                                    }
                                </Form.Item>
                                <Button htmlType={'submit'} type={"primary"} size={"large"} style={{width: '100%',margin: '15px auto 30px'}}>提交</Button>
                            </Form>
                        </Modal>
                    </div>
                </Fragment>
            }
        </div>
    )
}
