import React from 'react';
import css from './list.module.less';
import banner01 from 'aliasimgurl/banner_01.png'
import {getForestryType, resourcesUrl} from "../../../common/static_number";
import defaultImg from 'aliasimgurl/default_img.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {EmptyData} from "../../../components/empty-data";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_i3ev7426x1e.js',
});
export const List =(props)=>{
    const { data =[] ,toView} = props;
    const goDetail = (opt)=>{
        toView(opt);
    }
    return (
        <div >
            <div className={ css.list }>
                {
                    data.length>0?  data.map((opt,index)=>
                        <div className={ css.item } key={ index }  >
                            <img src={opt.images ? opt.images : defaultImg} alt="" className={css.img} onClick={()=>goDetail(opt)}/>
                            <div className={ css.title } onClick={()=>goDetail(opt)}>
                                {opt.title}
                            </div>
                        </div>
                    ) : <EmptyData />
                }
            </div>
        </div>

    );
}
