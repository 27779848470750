import React from 'react';
import css from './default-item.module.less';
import defaultImg from  'aliasimgurl/default_img.png';
import { useHistory} from 'react-router-dom'
import {resourcesUrl} from "../../../common/static_number";
import {createFromIconfontCN} from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6tmts73qde.js',
});

export const DefaultItem =(props)=>{
    const {item={}} = props;
    const {push} = useHistory();
    return (
        <div className={ css.item } onClick={()=>push({pathname: '/exchange/view/'+item.id})}>
            <img src={ item.images[0].file ? resourcesUrl + item.images[0].file : defaultImg} alt="" />
            <div className={css.box}>
                <div className={ css.title }>{ item.project_name }</div>
                <div className={ css.footer }>
                    <div><IconFont type={'icon-weizhi'}/><span className={css.text}>{item.pname}</span></div>
                    <div><IconFont type={'icon-time'}/><span className={css.text}>{item.releasetime}</span></div>
                </div>
            </div>

        </div>
    );
}