import React, {useRef} from 'react';
import css from './index.module.less';
import VipBanner from 'aliasimgurl/vip_banner.png'
import VipContent from 'aliasimgurl/vip_content.png'
import {ServicesModel} from "../../common";
import {ApplySuccessModal} from "./components";

export const Vip = (props) => {
    const modalRef = useRef();
    function handleOpen (v){
        ServicesModel.applyMember().pipe().subscribe({
            next:res=>{
                modalRef.current.showModal({content:'恭喜您开通VIP会员服务',date:res.data});
            },
            error:err=>{},
            complete:()=>{}
        });
    }
    const handleApplyMember =(v)=>{
        ServicesModel.applyMember().pipe().subscribe({
            next:res=>{
                modalRef.current.showModal({content:'恭喜您申请试用成功！',date:res.data});
            },
            error:err=>{},
            complete:()=>{}
        });
    }

    return (
        <div style={{background: '#fff'}}>
            <div className={css.header}>
                <div className="container" style={{position: 'relative'}}>
                    <img src={VipBanner} alt=""/>
                    <div className={css.openBtn} onClick={handleOpen}>立即开通</div>
                    <div className={css.tryBtn} onClick={handleApplyMember} >限时使用</div>
                </div>
            </div>
            <div className="container">
                <img src={VipContent} alt=""/>
            </div>
            <ApplySuccessModal ref={modalRef} />
        </div>
    );
}