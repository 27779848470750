import React from 'react';

import echarts from 'echarts'

/** 土地评分 */
export class Score extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.loadMap();
    }

     loadMap =()=> {
        const {periphery,economics,matching,massif,management} = this.props.data;
        console.log(" this.props.data", this.props.data);
         var data = [
             {
                 name: '周边环境',
                 max: 100,
                 value: periphery,
             },{
                 name: '宏观经济',
                 max: 100,
                 value: economics
             },{
                 name: '相关配套',
                 max: 100,
                 value: matching
             },{
                 name: '地块情况',
                 max: 100,
                 value: massif
             },{
                 name: '经营状况',
                 max: 100,
                 value: management
             }]

         var titleArr= [], seriesArr=[];
        const colors=[['#32CD9B', '#fff'],['#32CD9B', '#fff'],['#32CD9B', '#fff'], ['#32CD9B', '#fff'],['#32CD9B', '#fff'],['#32CD9B', '#fff']]
         data.forEach(function(item, index){
             titleArr.push(
                 {
                     text:item.name,
                     left: index * 20 +10+'%',
                     top:   "75%" ,
                     textAlign: 'center',
                     textStyle: {
                         fontWeight: 'normal',
                         fontSize: '16',
                         color: "#666666",
                         textAlign: 'center',
                     },
                 }
             );
             seriesArr.push(
                 {
                     name: item.name,
                     type: 'pie',
                     clockWise: false,
                     radius: [45, 48],
                     itemStyle:  {
                         normal: {
                             color: colors[index][0],
                             shadowColor: colors[index][0],
                             shadowBlur: 0,
                             label: {
                                 show: false
                             },
                             labelLine: {
                                 show: false
                             },
                         }
                     },
                     hoverAnimation: false,
                     center: [index * 20 + 10.5 +'%' , "50%"],
                     data: [ {
                         value: item.max-item.value,
                         name: 'invisible',
                         itemStyle: {
                             normal: {
                                 color: colors[index][1]
                             },
                             emphasis: {
                                 color: colors[index][1]
                             }
                         }
                     },{
                         value: item.value,
                         label: {
                             normal: {
                                 formatter: function(params){
                                     return params.value + '分';
                                 },
                                 position: 'center',
                                 show: true,
                                 textStyle: {
                                     fontSize: '20',
                                     color: colors[index][0]  //跟随其他颜色
                                 }
                             }
                         },
                     }]
                 }
             )
         });


        const  option = {
             title:titleArr,
             series: seriesArr
         }
        var myChart = echarts.init(document.getElementById('score'));

        myChart.setOption(option);
    }

    render(){
        return (
            <div >
                <div id={'score'}  style={{ width: '100%', height: 230 }} />
            </div>
        );
    }

}