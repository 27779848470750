import React, {useEffect, useState} from 'react';
import * as R from 'ramda';
import {Select} from 'antd';
import {useEffectOnce} from 'react-use';
import {ServicesModel} from '../common';
import {forkJoin} from 'rxjs';

export const AddressItem = (props)=>{
    const [data,setData] = useState([]);
    const [data1,setData1] = useState([]);
    const [data2,setData2] = useState([]);
    const [values,setValues ] = useState([]);
    const { value,size } = props;
    useEffect(()=>{

        ServicesModel.getCityData({pid:0,type:2}).subscribe({
            next:({data})=>{
                setData(data);
            }
        });
    },[]);
    useEffectOnce(()=>{
        if (value && value.length>0) {
            console.log("==============",value);

            setValues(value);
            let _v = R.dropLast(1,value);
            console.log("_v",_v);
            forkJoin(...value.map(opt=>ServicesModel.getCityData({pid:opt}))).subscribe({
                next:(res)=>{
                    if (res && res.length>0) {
                        setData1(res[0]? res[0].data : []);
                        setData2(res[1]? res[1].data : []);
                    };
                }
            });
        };
    });
    const handleChange = (opt)=>{
        let {index,v} = opt;
        let _v = values;
        _v[index] = v;
        _v = R.take((1+index),_v);
        setValues(_v);
        // let value = _v;
        // value.push(-1,-1,-1);
        props.onChange&&props.onChange(_v.slice(0,3));
        if (index===2) {
            return console.log(_v);
        };

        ServicesModel.getCityData({pid:v}).subscribe({
            next:({data})=>{
                // if(data.length){
                //     data.unshift({id: -1, name: "全部"});
                // }

                if (index===0 ) {
                    setData1(data);
                    setData2([]);
                }else{
                    setData2(data);
                }
            }
        });
    }

    return (
        <div style={{display:'flex'}}>
            {
                data.length> 0 &&
                <div style={{flex:1}}>
                    <Select style={{width:'100%'}}
                        placeholder={props.placeholder? props.placeholder : '请选择'}
                        size={ size || 'large' }
                        value={ values[0]|| void 0 }
                        onChange={ v=>handleChange({v,index:0}) }>
                        {
                            (data).map(opt=>
                                <Select.Option key={ opt.id } value={ opt.id }> { opt.name }</Select.Option>
                            )
                        }
                    </Select>
                </div>
            }
            {
                data1.length>0 &&
                <div style={{flex:1,marginLeft:8}}>
                    <Select style={{width:'100%'}}
                        placeholder="请选择"
                        size={ size || 'large' }
                        value={ values[1] || void 0 }
                        onChange={ v=>handleChange({v,index:1}) }>
                        {
                            (data1).map(opt=>
                                <Select.Option key={ opt.id } value={ opt.id }> { opt.name }</Select.Option>
                            )
                        }
                    </Select>
                </div>
            }{
                data2.length>0 &&
                <div style={{flex:1,marginLeft:8}}>
                    <Select style={{width:'100%'}}
                        placeholder="请选择"
                        size={ size || 'large' }
                        value={ values[2]|| void 0 }
                        onChange={ v=>handleChange({v,index:2}) }>
                        {
                            (data2).map(opt=>
                                <Select.Option key={ opt.id } value={ opt.id }> { opt.name }</Select.Option>
                            )
                        }
                    </Select>
                </div>
            }
        </div>
    )
}