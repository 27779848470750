import React ,{useState,useEffect}from 'react';
import {NavLink} from 'react-router-dom';
import css from './sub-nav.module.less';
import {createFromIconfontCN, UserOutlined} from '@ant-design/icons';
import {Badge} from "antd";
import {useMessageNumData} from "../hook";
import {PROJECT_URL, TENDER_URL} from "../common/static_number";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_n7xfb49k5dp.js',
});


export const SubNav = (props)=>{

    const subNav = [
        {
        label:'用户主页',
        icon: <IconFont type={'icon-home'}/>,
        path:'/account/home'
     },
         {
            label:'账户设置',
            icon: <UserOutlined />,
            path:'/account/setting'
        },
       {
        label:'土地管理',
        path:'/account/project',
        icon: <IconFont type={'icon-tree'}/>,
    // },{
    //     label:'评估管理',
    //     icon: <IconFont type={'icon-fengxianpinggu'} />,
    },{
        label:'招投标管理',
        icon: <IconFont type={'icon-zhaotoubiao'} />,
    },{
        label:'项目库管理',
        icon: <IconFont type={'icon-ku'} />,
    }, {
            label:'消息通知',
            icon: <IconFont type={'icon-message'} />,
            path:'/account/work'
    },{
        label:'收藏夹',
        path:'/account/collect',
        icon: <IconFont type={'icon-collect'} />,

    }];

    const [currentUrl,setCurrentUrl] = useState('');
    const [noticeNum]=  useMessageNumData();
    useEffect(() => {
        setCurrentUrl(window.location.pathname)
    }, [window.location]);

    const goDetail = (opt)=>{
        switch (opt.label) {
            case '评估管理': //地方项目
                window.open(PROJECT_URL + '/');
                break;
            case '项目库管理': //百大项目库
                window.open(PROJECT_URL + '/account/project/list');
                break;
            case '招投标管理': //招标
                window.open(TENDER_URL + '/account/project/list');
                break;
            default:
        }

    }
    return (
        <div className={ css.navList }>
            {
                subNav.map((opt,index)=>
                    <div key={ index }>
                        {
                           opt.path ?  <NavLink to={ opt.path } className={ opt.children ?css.nav: css.parentNav } activeClassName={ opt.children ? css.selected: css.selected}>
                                <div>
                                    <span>{opt.icon}&nbsp;&nbsp;&nbsp;{ opt.label }</span>
                                    {opt.label === '消息通知' && currentUrl !== opt.path && <Badge style={{padding:0}} count={noticeNum.msgcount ||0}  overflowCount={99} offset={[0,-2]}/>}
                                    {opt.label === '项目管理' && !currentUrl.includes(opt.path) && <Badge style={{padding:0}} count={noticeNum.opencount+noticeNum.commentcount+noticeNum.approvalcount || 0}  overflowCount={99} offset={[0,-2]}/>}
                                </div>
                            </NavLink>
                               : <div className={css.parentNav} >
                                <div onClick={()=>goDetail(opt)}>
                                    <span>{opt.icon}&nbsp;&nbsp;&nbsp;{ opt.label }</span>
                                </div>
                               </div>

                        }
                    </div>
                )
            }
        </div>
    )
}