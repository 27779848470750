import React, {useEffect, useState} from 'react';
import {Card, StatusWarp, TimeWarp, TypeWarp} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Form, Input, message} from 'antd';
import {useDataApi} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {createFromIconfontCN,PlusSquareOutlined} from '@ant-design/icons'
import {ProModal} from "../../../common";
import {FOREST_TYPE, getForestryType} from "../../../common/static_number";
import {EmptyData} from "../../../components/empty-data";

const {Search} = Input;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6haxcqr03.js',
});

export const Project = (props) => {

    const {push} = useHistory();
    const [params, setParams] = useState({
        page: 1,
        pagesize: 5,
        project_name: props.location.state ? props.location.state.project_sn : ''
    });
    const [form] = Form.useForm();
    const [{data: dataSource,setData, isLoading/*, isError */}, updateParams] = useDataApi();
    useEffect(() => {
        const {project_name:search_name,..._params} = params;
        updateParams({
            url: '/api/myforestlist',
            method: 'post',
            data: {search_name,..._params}
        })
    }, [params]);

    const handleTypeChange = (value) => {
        let _params = params;
        _params.forestry_type = value;
        setParams({..._params});

    };

    const handleStatusChange = (value)=>{
        let _params = params;
        _params.status = value;
        setParams({..._params});
    };

    const handleTimeChange = (value)=>{
        let _params = params;
        _params.time = value;
        setParams({..._params});
    };

    const handleSearch = (value) => {
        let _params = params;
        _params.project_name = value;
        _params.page = 1;
        setParams({..._params});
    };

    function projectNameChange(e) {
        params.project_name = e.target.value;
    }

    const goDetail = (opt)=>{
        const {id} = opt;
        push({pathname:'/exchange/view/'+id});
    };
    const [{data: apply,isLoading:applyLoading}] = useDataApi();

    useEffect(()=>{
        if(!applyLoading){
            form.setFieldsValue({
                money:apply.money,
                store_user_name:apply.store_user_name,
                store_user_phone:apply.store_user_phone,
                remark:apply.store_remark,
            })
        }

    },[apply,applyLoading]);

    const handleChange = (opt,v,index)=>{
        const {id} = opt;
        ProModal.addPro({id,stage_id:v,status_type:1}).then(({data: {msg, code,data}}) => {
            if (code === 200) {
                message.success("修改成功");

            } else {
                message.error(msg)
            }
        })
    };

    const updateStatus = (opt,v,index)=>{
        const {id} = opt;
        ProModal.addPro({id,status:v,status_type:1}).then(({data: {msg, code,data}}) => {
            if (code === 200) {
                const _data = dataSource;
                _data.list[index].status = v;
                setData({..._data});
                message.success("修改成功");

            } else {
                message.error(msg)
            }
        })
    };
    const onDetail = (opt)=>{
        if(opt.status === 3){
            message.success('项目已完成交易',3);
        }else{
            push({pathname:'/addproject',state:{id:opt.id,type:'detail'}})
        }
    }
    return (
            <div className={css.mainBody}>

                <Card title="土地管理" cardStyle={{padding: '20px 30px'}} extra={<div className={css.pubBtn} onClick={()=> push({pathname:'/addproject',state:{}})}>  <PlusSquareOutlined /><span>&nbsp;立即发布</span></div>}>
                    <div className={css.searchWrap}>
                        <Search
                            placeholder="请输入项目关键字进行搜索"
                            enterButton="搜索"
                            defaultValue={params.project_name}
                            onChange={projectNameChange}
                            onSearch={value => handleSearch(value)}
                        />
                    </div>
                    <StatusWarp label={'土地状态'} onChange={handleStatusChange}/>
                    <TypeWarp  label={'流转类别'} onChange={handleTypeChange}/>
                    <TimeWarp label={'发布时间'} onChange={handleTimeChange}/>
                    <div className={css.header}>
                        <div className={css.title}>详情</div>
                        <div className={css.status}>状态</div>
                        <div className={css.operation}>操作</div>
                    </div>
                    {
                        !isLoading && <div className={css.list}>
                            {
                                dataSource.data.length > 0 ? dataSource.data.map((opt,index) =>
                                    <div className={css.row} key={opt.project_sn}>
                                        <div className={css.title} onClick={()=>goDetail(opt)}>
                                            <div className={css.name}> {opt.project_name || '未命名'}</div>
                                            <div className={css.infoWrap}>
                                                <p >预期总价：{opt.forestry_price === 0 ?  '面议':opt.forestry_price + '万'}</p>
                                                <p >土地地区：{ opt.address}</p>
                                                <p >使用年限：{ opt.years }年</p>
                                                <p >土地面积：{ opt.measure_area }亩</p>
                                                <p >
                                                    土地权属：<span>{{1:'个人',2:'集体',3: '企业'}[opt.ownership]}</span>
                                                </p>
                                                <p >流转类别：{getForestryType(opt.forestry_type).name  }</p>
                                                {/*<p>*/}
                                                {/*    证件情况：<span>{{1:'土地证',2:'租赁合同',3: '承包合同',4:'流转合同'}[opt.warrant]}</span>*/}
                                                {/*</p>*/}
                                                <p>
                                                    土地类型：<span>{FOREST_TYPE.find(item=> item.value === opt.type).name }</span>
                                                </p>
                                                <p >发布时间：{opt.createtime}</p>
                                            </div>
                                        </div>
                                        <div className={css.status}>
                                            {
                                                function(){
                                                    switch (opt.status) {
                                                        case 0:
                                                            return <span >待审核</span>;
                                                        case 1:
                                                            return <span >已发布</span>;
                                                        case 2:
                                                            return <span >审核未通过</span>;
                                                        case 3:
                                                            return <span>已完成</span>;
                                                        default:
                                                    }
                                                }()
                                            }
                                        </div>
                                        <div className={css.operation} style={{width:'160px'}}>
                                            <div className={css.btn} onClick={()=>onDetail(opt)}>查看</div>
                                            <div className={css.btn} onClick={()=>push({pathname:'/addproject',state:{id:opt.id,type:'edit'}})}>编辑</div>
                                        </div>

                                    </div>
                                ): <EmptyData />
                            }
                            <YcPagination
                                total={dataSource.total || 0}
                                pageSize={8}
                                current={params.page}
                                onChange={(e) => {
                                    const _params = params;
                                    _params.page = e;
                                    setParams({..._params})
                                }}
                                style={{margin: '30px 0'}}/>
                        </div>

                    }
                </Card>
            </div>
    )
}
