import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {AddressItem, Breadcrumb, YcImage, YcPagination, YcRadio} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './list.module.less';
import {Form, Input,Button,Modal} from 'antd';
import moment from 'moment';



export const DatabaseList = (props) => {
    let { push   } = useHistory();
    const [ideaForm] = Form.useForm();
    const [searchValue, setSearch] = useState({type:1,page:1,pagesize:10});

    scrollToTop();
    const [ { isLoading,...listData },updateParams ]  =useDataApi({});
    useEffect(() => {
        updateParams({url:'/api/datalist',method:'post',data:{ ...searchValue}});
        window.scrollTo(0, 0);

    }, [searchValue]);
    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };

    function pageChange (v){
        updateValue({page:v})
    }
    function updateValue(value) {
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }
    function goDetail(opt){
        const {id} = opt;
        console.log("opt",opt)
        push({pathname:'/database/info/'+id})
    }

    return (
        <div>
            <div className={css.breadcrumb}>
                <div className="container">
                {!isLoading && <Breadcrumb list={ [{name:'数据中心',path:'/database'},{name: '数据动态'}] } />}
                </div>
            </div>
            <div className="container">
                <div className={ css.tabs }>
                    <div className={ css.tab }>
                        动态数据
                    </div>
                </div>
                <div className={css.bodyWarp}>
                    <div className={ css.listWrap }>
                        {
                            !isLoading && listData.data.data.map(item=>{
                                return <li className={css.item} key={item.id}>
                                    <span className={css.title} onClick={()=>goDetail(item)}>{item.title}</span>
                                    <span className={css.time}>{moment(new Date(item.updatetime * 1000)).format('YYYY-MM-DD')}</span>
                                </li>
                            })
                        }
                    </div>
                    <YcPagination
                        total={ listData.data.count || 0 }
                        current={ searchValue.page}
                        pageSize={searchValue.pagesize}
                        onChange={pageChange}
                        style={ {margin:'30px 0'} } />
                </div>

            </div>
        </div>

    );
}