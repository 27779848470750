import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {YcImage, YcPagination, YcRadio} from '../../components';
import {List} from './components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import BannerExchange from 'aliasimgurl/banner_exchange.jpg';
import {FOREST_AREA, FOREST_TIME, FOREST_TYPE, FORESTRY_PRICE, FORESTRY_TYPE} from "../../common/static_number";


export const ExchangeList = (props) => {
    let { push,location:{ pathname }  } = useHistory();
    const [searchValue, setSearch] = useState({'forestry_type':0,type: 0, 'forestry_price':0, 'time':0,'province_id':0,'measure_area':0});
    useEffect(()=>{
        if(props.location.state){
            setSearch({isLoading:true,...searchValue,...props.location.state});
        }else{
            setSearch({isLoading:true,...searchValue})
        }
        scrollToTop();
    },[props.location.state]);

    function updateValue(value) {
        console.log();
        let _v = { ...searchValue, ...value };
        setSearch(_v);
        scrollToTop();
    }
    const [{ ...areaData }] = useDataApi({url:'/api/getCity',method: 'post',data:{pid:0,type:2},initialData: []});
    const [ { isLoading,...listData },updateParams ]  =useDataApi({});
    const [searchData,setSearchData] = useState([])
    useEffect(() => {
        if(searchValue.isLoading){
            updateParams({url:'/api/foretryList',method:'post',data:{page:1,pagesize:8, ...searchValue}});
        }
    }, [searchValue]);
    useEffect(() => {
        if(!areaData.isLoading){
            const areaList  = areaData.data.map((item)=>{
                const {name,id:value} = item;
                return {name,value}
            });
            const serachData = [
                {
                    items: [{name:'不限',value: 0 },...FORESTRY_TYPE],
                    label:'流转类别',
                    name:'forestry_type',
                    defaultValue: searchValue.forestry_type
                }, {
                    items: [{name:'不限',value: 0 },...FOREST_TYPE],
                    label:'土地类型',
                    name:'type',
                    defaultValue: searchValue.type
                },{
                    items: FORESTRY_PRICE,
                    label:'预期总价',
                    name:'forestry_price',
                    defaultValue: searchValue.forestry_price
                },{
                    items:[{name:'不限',value: 0},...areaList] ,
                    label:'土地地区',
                    name:'province_id',
                    defaultValue: searchValue.province_id
                },{
                    items: FOREST_AREA,
                    label:'土地面积',
                    name:'measure_area',
                    defaultValue: searchValue.measure_area
                },{
                    items: FOREST_TIME,
                    label:'发布时间',
                    name:'time',
                    defaultValue: searchValue.time
                }
            ]
            setSearchData(serachData);
        }
    }, [areaData.isLoading]);
    function toView(opt) {
        const {id} = opt;
        push({pathname:'/exchange/view/'+id})
    }
    function pageChange (v){
        updateValue({page:v})
    }

    function scrollToTop(argument) {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 2);
        };
    };
    return (
        <div className="container">
            <div className={css.banner}>
                <YcImage imgUrl={ BannerExchange }/>
                <div className={css.btn} onClick={()=>push({pathname:'/addproject',state:{}})} >前往发布土地信息</div>
            </div>
            <div className={css.bodyWarp}>
                <div className={ css.searchWrap }>
                    {
                        searchData.length>0 &&  searchData.map((opt,index)=>
                            <YcRadio
                                key={ index }
                                items={ opt.items }
                                className={ css.searchItem }
                                label={ opt.label }
                                name={ opt.name }
                                value={ opt.defaultValue }
                                more
                                onChange={ updateValue } />
                        )
                    }
                </div>
                <div className={ css.listWrap }>
                    <List itemsLength={ 16 } data={ listData.data.data || [] } count={listData.data.count} toView={ toView } />
                    <YcPagination
                        total={ listData.data.count || 0 }
                        current={ searchValue.page}
                        pageSize={8}
                        onChange={pageChange}
                        style={ {margin:'30px 0'} } />
                </div>
            </div>

        </div>
    );
}