import React, {useEffect, useState} from 'react';
import Swiper from 'react-id-swiper';
import * as R from 'ramda';
import newsimg from 'aliasimgurl/img_news_demo.png';
import css from './subject-list.module.less';
import {useDataApi} from "../hook";
import {useHistory} from "react-router-dom";

export const SubjectList =(props)=>{
    const {push} = useHistory();
    const {items=[]} = props;
    const goDetail= (id)=>{
        push({pathname:'/info/subview/'+id})
    }

    const [after,setAfter] = useState([]);
    const [before,setBefore] = useState([]);
    const [swiper, updateSwiper] = useState(null);
    const [ { ...newlist } ]  =useDataApi({url:'/api/newsList',method:'post',data:{page:1,pagesize:10,type: 6}});

    useEffect(()=>{
        if(items.length){
            let [_before,_after] = R.splitAt(2,items);
            setAfter([..._after]);
            setBefore([..._before]);
        }

    },[items]);

    const onError = ()=>{
        let _r = before.map(opt=>({...opt,images:newsimg}));
        setBefore(_r);
    };

    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        hideOnClick: true,
        hiddenClass: 'my-button-hidden',
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            clickable: true
        },

        getSwiper: updateSwiper,
        on: {
            init: function(){
                setTimeout(()=>{
                    this.navigation.$nextEl.css('display','none');
                    this.navigation.$prevEl.css('display','none');
                },50)
            },
        }
    };
    const handleMouseOver = ()=>{
        swiper.autoplay.stop();
        swiper.navigation.$nextEl.css('display','block');
        swiper.navigation.$prevEl.css('display','block');
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start();
        swiper.navigation.$nextEl.css('display','none');
        swiper.navigation.$prevEl.css('display','none');
    };
    return (
        <div className={ css.list }>
            {!newlist.isLoading &&   <div className={css.swiper} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <Swiper { ...params }  >
                    {
                        newlist.data.data.map((opt,index)=>
                            <div className={ css.item } key={ index } onClick={()=>goDetail(opt.id)}>
                                <img style={ {fontSize:0,display:'block'} }
                                     onError={ (e)=>onError(e) } src={ opt.images } alt="图片"/>
                                     <div className={css.content}><span className={css.tag}>专题</span>{opt.title}</div>
                            </div>
                        )
                    }
                </Swiper>
            </div>}
        </div>
    );
}