import React, {useState} from 'react';
import css from './statusWarp.module.less';
import {BusinessType} from "../../../helps";

export const StatusWarp  = (props)=>{
    const {label} = props;
    const [status,setStatus] = useState('');
    const handleChange = (value) =>{
        setStatus(value);
        props.onChange && props.onChange(value)
    };

    return (
        <div className={ css.statusWarp }>
            <div className={css.typeBox}>
                <div className={css.label}>{label}：</div>
                <div className={status === '' ? css.active : ''}
                     onClick={() => handleChange('')}>全部
                </div>
                <div className={status === 0 ? css.active : ''}
                     onClick={() => handleChange(0)}>等待审核
                </div>
                <div className={status === 2 ? css.active : ''}
                     onClick={() => handleChange(2)}>审核未通过
                </div>
                <div className={status === 1 ? css.active : ''}
                     onClick={() => handleChange(1)}>已发布
                </div>
                <div className={status === 3 ? css.active : ''}
                     onClick={() => handleChange(3)}>已完成
                </div>
            </div>
        </div>
    )
}


export const TypeWarp  = (props)=>{
    const {label} = props;
    const [status,setStatus] = useState('');
    const handleChange = (value) =>{
        setStatus(value);
        props.onChange && props.onChange(value)
    };

    return (
        <div className={ css.statusWarp }>

            <div className={css.typeBox}>
                <div className={css.label}>{label}：</div>
                <div className={status === '' ? css.active : ''}
                     onClick={() => handleChange('')}>全部
                </div>
                <div className={status === '1' ? css.active : ''}
                     onClick={() => handleChange('1')}>转让
                </div>
                <div className={status === '2' ? css.active : ''}
                     onClick={() => handleChange('2')}>出租
                </div>
                <div className={status === '3' ? css.active : ''}
                     onClick={() => handleChange('3')}>转包
                </div>
                <div className={status === '4' ? css.active : ''}
                     onClick={() => handleChange('4')}>合作
                </div>
                <div className={status === '5' ? css.active : ''}
                     onClick={() => handleChange('5')}>置换
                </div>
            </div>
        </div>

    )
}

export const IndustryWarp  = (props)=>{
    const {label,value} = props;
    const [status,setStatus] = useState(typeof(value) === 'string'? value.split(','): []);
    const handleChange = (value) =>{
        let _status = status;
        console.log("status.includes(value)",status.includes(value));
        console.log("_status.indexOf()",_status.indexOf(value));
        console.log("value",value);
        if(status.includes(value)){
            _status.splice(_status.indexOf(value),1);

        }else{
            _status = [value,..._status];

        }
        console.log("_status.indexOf()",_status);

        setStatus([..._status]);
        props.onChange && props.onChange(_status);
    };

    return (
        <div className={ css.industryWarp }>

            <div className={css.typeBox}>
                {
                    BusinessType.types.map(opt=>
                        <div className={status.includes(opt.code) ? css.active : ''} key={opt.code}
                             onClick={() => handleChange(opt.code)}>{opt.label}
                        </div>
                    )
                }
            </div>
        </div>

    )
}

export const TimeWarp  = (props)=>{
    const {label} = props;
    const [status,setStatus] = useState('');
    const handleChange = (value) =>{
        setStatus(value);
        props.onChange && props.onChange(value)
    };
    return (
        <div className={ css.statusWarp }>
            <div className={css.typeBox}>
                <div className={css.label}>{label}：</div>
                <div className={status === '' ? css.active : ''}
                     onClick={() => handleChange('')}>全部
                </div>
                <div className={status === '1' ? css.active : ''}
                     onClick={() => handleChange('1')}>今天
                </div>
                <div className={status === '2' ? css.active : ''}
                     onClick={() => handleChange('2')}>本周
                </div>
                <div className={status === '3' ? css.active : ''}
                     onClick={() => handleChange('3')}>本月
                </div>
                <div className={status === '4' ? css.active : ''}
                     onClick={() => handleChange('4')}>近半年
                </div>
            </div>
        </div>

    )
}