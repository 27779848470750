import React, {Fragment} from 'react';
import css from './obj-box.module.less';

export const ObjBox =(props)=>{
    const { children,title,subtitle,icon,style } = props;
    return (
        <div className={ css.box } style={style}>
            <div className={css.icon}>{icon}</div>
            <div className={ css.title }>{title}</div>
            <div className={ css.subtitle }>{subtitle}</div>
            <div className={css.children}>
                {children}
            </div>
        </div>
    );
}