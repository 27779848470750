import React, {useEffect,useState} from 'react';
import {useEffectOnce} from 'react-use';
import {Breadcrumb, CardBox, DefaultList, HotInfoList,} from '../../components';
import {message} from 'antd';
import {useDataApi} from '../../hook';
import css from './index.module.less';
import {ProModal} from "../../common";
import {tap} from "rxjs/operators";
import {useHistory} from 'react-router-dom'
import {ExchangeBox} from "../../components/exchange-box";
import {SubjectList} from "../../components/subject-list";
import {createFromIconfontCN} from '@ant-design/icons';
import {TYPE} from "../../common/static_number";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_l6tmts73qde.js',
});
export const SubView = (props) => {
    const {id=''} = props.match.params ? props.match.params : {};
    const [{data,isLoading,setData},updateParams] = useDataApi();
    const {push} = useHistory();
    const [ { ...newlist } ]  =useDataApi({url:'/api/newsList',method:'post',data:{page:1,pagesize:10,type: 1}});

    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/getNewsDetail', method: 'post', data: {newstype:1,id}})
    },[id]);

    /** 收藏 */
    const handleCollect = ()=>{
        ProModal.collect({id,type:10}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        ProModal.cancelCollect({id,type:10}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    return (
        <div >
            <div className="container">
                {!isLoading && <Breadcrumb list={ [{name:'土地资讯',path:'/info'},{name:TYPE[data.newtype]||""}] } />}
            </div>
            <div style={{background: '#fff'}}>


                <div className="container" >
                {!isLoading &&
                    <div className={css.bodyWarp}>
                        <div className={css.leftWarp}>
                            <div className={ css.viewWrap }>
                                <div className={ css.titleInfo }>
                                    <div className={ css.viewTitle }>{data.title}</div>
                                    <p>
                                        <span className={css.text}>{data.createtime}</span>
                                        {data.source && <span className={css.text}>来源：{data.source}</span>}
                                        <span className={css.text}>点击量：{data.read_num}</span>
                                        {
                                            data.is_collect?  <span className={css.check}  onClick={handleCancelCollect} style={{cursor: "pointer"}}>
                                                <IconFont type={'icon-collection-b'}/>
                                                <span className={css.text}>已收藏</span>
                                            </span>:<span onClick={handleCollect} style={{cursor: "pointer"}}>
                                               <IconFont  type={'icon-shoucang1'}/><span className={css.text} >收藏</span>
                                            </span>
                                        }
                                    </p>
                                </div>
                                <div className={css.desInfo} dangerouslySetInnerHTML={{__html: data.content}}/>
                            </div>
                        </div>
                        <div className={css.rightWarp}>
                            {/*<HotCityList style={{marginTop: '20px'}}/>*/}
                            <CardBox title={'新闻快讯'} style={{background: '#F5F5F5',marginTop:'30px',minHeight:'300px'}}>
                                <DefaultList  items={newlist.data.data}/>
                            </CardBox>
                            <ExchangeBox style={{marginTop: '30px'}}/>
                        </div>
                    </div>
                }
            </div>
            </div>
        </div>
    )
}
