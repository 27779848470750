import React from 'react';
import css from './new-item.module.less';
import defaultImg from 'aliasimgurl/default_img.png'
import {PLAN_TYPE, resourcesUrl} from "../../../common/static_number";

export const PickItem =(props)=>{
    const {item={},goDetail} = props;
    return (
        <div className={ css.item } onClick={goDetail}>
            <img src={item.images[0].file ? resourcesUrl + item.images[0].file : defaultImg} alt=""/>
            <div className={css.box}>
                <div className={ css.title }>{ item.project_name }</div>
                <div className={ css.footer }>精选项目</div>
            </div>
        </div>
    );
}