import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './exchange-swiper.module.less';
import {useHistory} from "react-router-dom";
import {useIsLogin} from "../../../hook";
import banner01 from 'aliasimgurl/banner_01.png'
import {resourcesUrl} from "../../../common/static_number";
import defaultImg from 'aliasimgurl/default_img.png'

export const ExchangeSwiper =(props)=>{
    const {banners} = props;
    const onError = (e)=>{
        console.log(e);
    }
    const [swiper, updateSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);


    const {push} = useHistory();
    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        hideOnClick: true,
        hiddenClass: 'my-button-hidden',
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        getSwiper: updateSwiper,
        on: {
            init: function(){
                setTimeout(()=>{
                    this.navigation.$nextEl.css('display','none');
                    this.navigation.$prevEl.css('display','none');
                },50)
            },
            slideChange: function () {


                setActiveIndex(this.realIndex);
            },
        }
    };

    const handleMouseOver = ()=>{
        swiper.autoplay.stop();
        swiper.navigation.$nextEl.css('display','block');
        swiper.navigation.$prevEl.css('display','block');
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start();
        swiper.navigation.$nextEl.css('display','none');
        swiper.navigation.$prevEl.css('display','none');
    };

   const swiperChange = (index)=>{
        swiper.slideTo(index);
        setActiveIndex(index);
    };


    return (

        <div >

            <div className={css.swiper} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {banners.length && <Swiper { ...params }  >
                    {
                        banners.map((opt,index)=>
                            <div className={ css.item } key={ opt.file } >
                                <img style={ {fontSize:0,display:'block'} }
                                     onError={ (e)=>onError(e) } src={ opt.file  ? resourcesUrl + opt.file : defaultImg } alt="图片"/>
                            </div>
                        )
                    }
                </Swiper>
                }
            </div>
            <div className={css.switchBox}>
                {
                    banners.map((opt,index)=>
                        <img key={opt.uid} src={ opt.file  ? resourcesUrl + opt.file : defaultImg} className={activeIndex === index?css.active:''} onMouseOver={()=>swiperChange(index)}/>
                    )
                }
            </div>


        </div>

    );
}