import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {YcImage} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import {CardBox, NewItem, ObjBox} from "./components";
import classNames from 'classnames';
import {Box} from "./components/box";
import Flow01 from 'aliasimgurl/flow01.png';
import Flow02 from 'aliasimgurl/flow02.png';
import Flow03 from 'aliasimgurl/flow03.png';
import Flow04 from 'aliasimgurl/flow04.png';
import Flow05 from 'aliasimgurl/flow05.png';
import Flow06 from 'aliasimgurl/flow06.png';
import {createFromIconfontCN} from '@ant-design/icons';
import {Button} from 'antd';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1696985_x5swnlkunbr.js',
});
export const Design = (props) => {
    let { push } = useHistory();

    const [ { data,isLoading } ]  =useDataApi({url:'/api/planslist',method:'post',data:{page:1,pagesize:8,plan_type:0}});
    const [current ,setCurrent] = useState('services')

    const servicesRef = useRef();
    const objBoxRef = useRef();
    const flowRef = useRef();
    const listRef = useRef();
    const observer = useRef();

    const initObserver = ()=>{
        const idList = ['services','objBox','flow','list'];
        const Observer  = new IntersectionObserver(entries =>{
            entries.forEach(elem =>{
                if(elem.boundingClientRect.y< 0 ){
                    if(Math.abs(elem.boundingClientRect.y) + 70 < elem.boundingClientRect.height){
                        // console.log("向上",elem);
                        setCurrent(elem.target.id);
                    }else{
                        // console.log("向下",elem);
                        setCurrent(idList[idList.indexOf(elem.target.id)+1]);
                    }
                }
            })
        },{threshold: [0],rootMargin:'-70px 0px 70px 0px'});
        Observer.observe(servicesRef.current);
        Observer.observe(objBoxRef.current);
        Observer.observe(flowRef.current);
        Observer.observe(listRef.current);
        observer.current = Observer;
    };

    useEffect(()=>{
        if(!isLoading){
           initObserver();
        }
    },[isLoading])
    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            // 找到锚点
            let x = '';
            switch (anchorName) {
                case 'services':
                    x = servicesRef;
                    break;
                case 'objBox':
                    x = objBoxRef;
                    break;
                case 'flow':
                    x = flowRef;
                    break;
                case 'list':
                    x = listRef;
                    break;
                default:;
            }
            console.log("x",x.current.offsetTop);
            console.log("x",x);
            window.scrollTo(0,x.current.offsetTop-60);
            setCurrent(anchorName);

        }
    };
    return (
        <div style={{background: '#fff'}} className={css.index}>
            <div className={classNames(css.banner)}>
                {/*<img src={ bannerDesign }  style={{'cursor': 'pointer',width: '100%',height: '392px'}}/>*/}
                {/*<div className={css.bannerText}>已帮助<span>60</span>个小镇成功申报！</div>*/}
            </div>
            <div style={{background: '#eee'}} className={css.tabsBox}>
                <div className={classNames(css.tabs,'container')}>
                    <div  className={classNames(css.tab,current === 'services' ? css.active:'')} onClick={()=>scrollToAnchor('services')}>
                        服务领域
                    </div>
                    <div className={classNames(css.tab,current === 'objBox' ? css.active:'')} onClick={()=>scrollToAnchor('objBox')}>
                        服务对象
                    </div>
                    <div  className={classNames(css.tab,current === 'flow' ? css.active:'')} onClick={()=>scrollToAnchor('flow')}>
                        服务流程
                    </div>
                    <div  className={classNames(css.tab,current === 'list' ? css.active:'')} onClick={()=>scrollToAnchor('list')}>
                        经典案例
                    </div>
                </div>

            </div>

            <div className={css.indexWarp}>
                <div  id={'services'} ref={servicesRef}>
                    <Box title={'服务领域'} subtitle={'RANGE  OF  SERVICES'}>
                        <div className={css.services}>
                            <CardBox title={"森林康养规划设计"} >
                                <p>森林康养申报</p>
                                <p>康养旅游策划</p>
                                <p>康养旅游规划</p>
                                <p>康养旅游设计</p>
                                <p>康养设备服务</p>
                                <p>康养项目运营管理</p>
                            </CardBox>
                            <CardBox title={"国土空间规划"} >
                                <p>概念性规划</p>
                                <p>总体规划</p>
                                <p>控制下详情规划</p>
                                <p>修建性详情规划</p>
                                <p>城市专项规划</p>
                                <p>城市空间规划</p>
                            </CardBox>
                            <CardBox title={"景观设计"} >
                                <p>景观设计</p>
                                <p>主题乐园</p>
                                <p>私家林园</p>
                                <p>古村景观提升</p>
                                <p>别墅后花园</p>
                            </CardBox>
                            <CardBox title={"产业规划"} >
                                <p>产业发展规划</p>
                                <p>区域经济规划</p>
                                <p>专项产业规划</p>
                                <p>产业园区规划</p>
                                <p>产业地产规划</p>
                                <p>城镇体系规划</p>
                            </CardBox>
                            <CardBox title={"特色小镇规划设计"} >
                                <p>旅游小镇</p>
                                <p>森林小镇</p>
                                <p>科技小镇</p>
                                <p>农业小镇</p>
                                <p>商贸小镇</p>
                                <p>文化创意小镇</p>
                            </CardBox>
                        </div>
                    </Box>
                </div>
                <div id={'objBox'} ref={objBoxRef}>
                    <Box title={'服务对象'} subtitle={'TARGETED  CLIENT  GROUPS'} style={{background:'#F7FDF9'}} >
                        <div className={css.objBox} >
                            <ObjBox title={'各级政府'} icon={<IconFont type={'icon-zhengfu'}/>}>
                                <p>产业规划</p>
                                <p>土地规划</p>
                                <p>招商策划</p>
                                <p>空间规划</p>
                                <p>产业基金</p>
                                <p>产业投资</p>
                            </ObjBox>
                            <ObjBox title={'园区管委会'} icon={<IconFont type={'icon-guanweihui'}/>}>
                                <p>产业规划</p>
                                <p>空间规划</p>
                                <p>产业升级</p>
                                <p>产业营销</p>
                                <p>产业投资</p>
                                <p>园区运营</p>
                            </ObjBox>
                            <ObjBox title={'产业投资者'} icon={<IconFont type={'icon-touzi'}/>}>
                                <p>项目评估</p>
                                <p>投资研判</p>
                                <p>项目选址</p>
                                <p>规划设计</p>
                                <p>融资支持</p>
                                <p>资源导入</p>
                            </ObjBox>
                            <ObjBox title={'林农/林业'} icon={<IconFont type={'icon-linyeting'}/>}>
                                <p>林产规划</p>
                                <p>林下经济</p>
                                <p>森林康养</p>
                                <p>规划设计</p>
                                <p>招商策划</p>
                                <p>林业投资</p>
                            </ObjBox>
                        </div>

                    </Box>
                </div>
                <div  id={'flow'} ref={flowRef}>
                    <Box title={'服务流程'} subtitle={'SERVICE  PROCESS'}>
                        <div className={css.flow}>
                            <div className={css.flowItem}>
                                <img src={Flow01} className={css.iconIndex}/>
                                <div className={css.flowTitle}>项目启动</div>
                                <div>
                                    <p>团队组建</p>
                                    <p>工作计划</p>
                                    <p>调研提纲</p>
                                    <p>资料清单</p>
                                </div>
                            </div>
                            <div className={css.flowItem}>
                                <img src={Flow02} className={css.iconIndex}/>
                                <div className={css.flowTitle}>现场调研</div>
                                <div>
                                    <p>现场勘探</p>
                                    <p>人员对接</p>
                                    <p>部门座谈</p>
                                    <p>资料收集</p>
                                </div>
                            </div>
                            <div className={css.flowItem}>
                                <img src={Flow03} className={css.iconIndex}/>
                                <div className={css.flowTitle}>规划资讯</div>
                                <div>
                                    <p>项目研究</p>
                                    <p>专家访谈</p>
                                    <p>案例考察</p>
                                    <p>中期汇报</p>
                                </div>
                            </div>
                            <div className={css.flowItem}>
                                <img src={Flow04} className={css.iconIndex}/>
                                <div className={css.flowTitle}>深度研究</div>
                                <div>
                                    <p>补充调研</p>
                                    <p>深化研讨</p>
                                    <p>撰写成果</p>
                                </div>
                            </div>
                            <div className={css.flowItem}>
                                <img src={Flow05} className={css.iconIndex}/>
                                <div className={css.flowTitle}>项目评审</div>
                                <div>
                                    <p>内部评审</p>
                                    <p>正式汇报</p>
                                    <p>专家评审</p>
                                </div>
                            </div>
                            <div className={css.flowItem}>
                                <img src={Flow06} className={css.iconIndex}/>
                                <div className={css.flowTitle}>完成规划</div>
                                <div>
                                    <p>报告修订</p>
                                    <p>成功提交</p>
                                </div>
                            </div>
                        </div>

                    </Box>
                </div>
                <div id={'list'} ref={listRef}>
                    { !isLoading &&
                    <Box title={'经典案例'} subtitle={'CLASSIC  CASES'} style={{background:'#F5F5F5'}} >
                        <NewItem data={data.list}/>
                        <div className={css.btns}>
                            <Button type="primary" size={"large"} onClick={()=>push({pathname:'/design/list',state:{}})}>查看更多</Button>
                        </div>
                    </Box>
                    }
                </div>




                </div>
        </div>
    );
}