import React from 'react';
import classNames from 'classnames';
import css from './InfoList.module.less';
import {useHistory} from "react-router-dom";
import {EmptyData} from "../../../components/empty-data";

export const InfoList = (props)=>{
    const {data=[],handleCancelCollect} = props;
    const {push} = useHistory();
    const goDetail = (opt)=>{
        console.log("opt",opt)
        const {cid:id} = opt;
        if(opt.ntype === 12){
            push({pathname:'/database/info/'+ id});

        }else{
            push({pathname:'/expert/info/view/'+ id});
        }

    };
    return (
        <div className={css.box}>
            <div className={css.list}>
                <div className={classNames(css.header)}>
                    <div className={css.name}>标题</div>
                    <div className={css.status} >资讯类别</div>
                    <div className={css.status} >发布时间</div>
                    <div className={css.operation}>操作</div>
                </div>
                { data.map((opt,index)=>
                    <div className={classNames(css.row)}>
                        <div className={css.name}  onClick={()=>goDetail(opt)}>
                            {opt.project_name|| '未命名'}
                        </div>
                        <div className={css.status}>{{1:'新闻快讯',2:'通知公告',3:'政策法规',4:'培训交流',5:'土地百科',6:'',7:'专家访谈',8:'专家动态',9:'专家文献',10:'专家观点',12:'数据动态'}[opt.ntype] }</div>
                        <div className={css.time}>{opt.createtime}</div>
                        <div className={css.operation}>
                            <div className={css.btn} onClick={()=>handleCancelCollect(opt,index)}>取消收藏</div>
                        </div>
                    </div>

                )}
                {
                    !data.length && <EmptyData />
                }
            </div>
        </div>


    )
}