import React, {Fragment} from 'react';
import css from './card-box.module.less';

export const CardBox = (props)=>{
    const { children,title,style } = props;
    return (
        <div className={ css.cardbox } style={style}>
            <div className={ css.title } >
                <span>
                     {title}
                </span>
            </div>
            <div className={css.children}>
                {children}
            </div>
        </div>
    );
}