import React,{useState,forwardRef,useImperativeHandle} from 'react';
import {Modal,Button} from 'antd';
import css from './vipModal.module.less';
import {useHistory} from "react-router-dom";

export const ApplySuccessModal = forwardRef((props,ref)=>{
    const [data,setData] = useState({});
    const [visible,setVisibel] = useState(false);
    const {push} = useHistory();
    useImperativeHandle(ref, () => ({
        showModal:(data)=>{
            //需要处理的数据
            setVisibel(true)
            setData({...data})
        },
        cancel :() =>{
            setVisibel(false)
        }
    }))

    const cancel =() =>{
        setVisibel(false)
    };

    const goDetail = ()=>{
        push('/account/setting');
    }

    return (

        <Modal  title={'提示'} visible={visible} onCancel={cancel} footer={null} >
            <div className={css.modal}>
                <div>{data.content}</div>
                <div>会员有效期至：{data.date}</div>
                <div className={css.btn} onClick={goDetail}>查看</div>
            </div>
        </Modal>

    );
})

