import React,{useState} from 'react';
import css from './add-project-industry.module.less';
import {Row,Col,Button} from 'antd';
import { FORESTRY_TYPE} from "../common/static_number";

export const AddProjectIndustry = (props)=>{
    const [sub, setSub] = useState(props.value);
    const handleClick = (item)=>{
        setSub(item.value);
        props.onChange(item.value)
    };
    return (
        <div className={css.industry}>
            {FORESTRY_TYPE.map((item,index)=>{
                return  <Button style={{textAlign: 'left'}} className={ sub === item.value? css.check:''} onClick={()=>handleClick(item)}>{item.name}</Button>
            })}
        </div>
    )
}