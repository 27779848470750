import React, {useState} from 'react';
import {useHistory} from 'react-router-dom'
import {useDataApi} from '../../hook/dataApi';
import css from './index.module.less';
import {ObjBox} from "./components";
import classNames from 'classnames';
import {Box} from "./components/box";
import {Form, Input,message,Modal} from 'antd';

import icon01 from 'aliasimgurl/meet01.png';
import icon02 from 'aliasimgurl/meet02.png';
import expertBannerImg from 'aliasimgurl/expert_banner_img.png';
import expertBanner01 from 'aliasimgurl/expert_banner_01.png';
import Swiper from 'react-id-swiper';
import {AddressItem} from "../../components";
import {ServicesModel} from "../../common";
import {EXPERT_URL} from "../../common/static_number";

export const Expert = (props) => {
    let {push} = useHistory();
    const [type,setType] = useState(0);
    const [ideaForm] = Form.useForm();
    const typeList = ['fangtan','dongtai','wenxian','guandian'];
    const [{data, isLoading}] = useDataApi({
        url: '/api/expertIndex',
        method: 'post',
        data: {}
    });
    const params = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
    };

    const goDetail = (opt) => {
        const {id=''} = opt;
        push({pathname:'/expert/info/view/'+id})
    };
    const goExpert = (type) => {
       switch (type) {
           case 'login':
               window.open(EXPERT_URL+'/expert/login');
               break;
           case 'applyBid':
               window.open(EXPERT_URL+'/expert/reg/0/3');
               break;
           case 'applyIndustry':
               window.open(EXPERT_URL+'/expert/reg/0/4');
               break;
           default:
       }
    };
    /** 联系我们 */
    const saveIdea = ()=>{
        ideaForm.validateFields().then((v)=>{
            const [province_id, city_id, county_id] = v.area;
            ServicesModel.addProposal({type:5,province_id, city_id, county_id,...v}).subscribe({
                next:res=>{
                    ideaForm.resetFields();
                    Modal.success({
                        title: '您提交的咨询信息已发送成功，请等待工作人员与您联系!',
                        content: `如有其它疑问请致电 0871-65819655`,
                    });
                }
            })
        })

    };
    return (
        <div style={{background: '#fff'}} className={css.index}>
            <div className={classNames(css.banner)}>
                <div className={classNames('container', css.header)}>
                    <div className={css.leftWarp}>
                        <div className={css.title}>
                            目前已有<span className={css.num}>1203&nbsp;</span>位专家已加入专家智库
                        </div>
                        <div className={css.subtitle}>汇集全国各行业专家，致力于为客户匹配撮合</div>
                        <div className={css.subtitle}>专家顾问、打造本行业专属的智囊团</div>
                        <div className={css.btns}>
                            <div onClick={()=>goExpert('applyIndustry')}>申请成为行业专家</div>
                            <div onClick={()=>goExpert('applyBid')}>申请成为评标专家</div>
                            <div onClick={()=>goExpert('login')}>进入专家智库</div>
                        </div>
                    </div>
                    <div className={css.rightWarp}>
                        <img src={expertBannerImg} alt=""/>
                    </div>
                </div>
            </div>

            <div className={css.indexWarp}>
                <Box title={'专家资讯'}>
                    {
                        !isLoading && <div className={css.info}>
                            <div className={css.swiper}>
                                <Swiper {...params} >
                                    <div className={css.item} onClick={() => goDetail( data.fangtan[0])}>
                                        <img style={{fontSize: 0, display: 'block'}} src={ data.fangtan[0] && data.fangtan[0].images} alt="图片"/>
                                    </div>
                                    <div className={css.item} onClick={() => goDetail( data.dongtai[0])}>
                                        <img style={{fontSize: 0, display: 'block'}} src={ data.dongtai[0] && data.dongtai[0].images} alt="图片"/>
                                    </div>
                                    <div className={css.item} onClick={() => goDetail( data.wenxian[0])}>
                                        <img style={{fontSize: 0, display: 'block'}} src={ data.wenxian[0] && data.wenxian[0].images} alt="图片"/>
                                    </div>
                                    <div className={css.item} onClick={() => goDetail( data.guandian[0])}>
                                        <img style={{fontSize: 0, display: 'block'}} src={ data.guandian[0] && data.guandian[0].images} alt="图片"/>
                                    </div>
                                </Swiper>

                            </div>
                            <div className={css.rightWarp}>
                                <div className={css.titleBox}>
                                    <div className={css.tabsBox}>
                                        <div className={classNames(css.tab, type === 0 && css.active)}
                                             onClick={() => setType(0)}>
                                            专家访谈
                                        </div>
                                        <div className={classNames(css.tab, type === 1 && css.active)}
                                             onClick={() => setType(1)}>
                                            专家动态
                                        </div>
                                        <div className={classNames(css.tab, type === 2 && css.active)}
                                             onClick={() => setType(2)}>
                                            专家文献
                                        </div>
                                        <div className={classNames(css.tab, type === 3 && css.active)}
                                             onClick={() => setType(3)}>
                                            专家观点
                                        </div>
                                    </div>
                                    <div className={css.more} onClick={()=>push('/expertInfoList')}>
                                        更多>
                                    </div>
                                </div>

                                {data[typeList[type]].length>0 && <div>
                                    { data[typeList[type]].map((item,index)=>{
                                        if(item.flag === 'index'){
                                            return (<div className={css.box} onClick={()=>goDetail(data[typeList[type]][0])}>
                                                <div className={classNames(css.title, 'line1')}>
                                                    {data[typeList[type]][0].title}
                                                </div>
                                                <div className={classNames(css.content, "line3")}>
                                                    {data[typeList[type]][0].remark}
                                                </div>
                                            </div>)
                                        }
                                    }) }
                                </div> }
                                <div className={css.listWrap}>
                                    { data[typeList[type]].map((item,index)=>{
                                        if(!item.flag){
                                            return (<div className={css.row} onClick={()=>goDetail(item)}>
                                                <div className={classNames(css.title,'line1')}>{item.title}</div>
                                                <div className={css.time}>{item.createtime}</div>
                                            </div>)
                                        }
                                    }) }
                                </div>
                            </div>
                        </div>
                    }

                </Box>
                <Box title={'服务流程'}>
                    <div className={css.flow}>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>1.提出需求</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>2.协调专家</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>3.达成合作意向</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>4.支付保证金</div>
                        </div>
                        <div className={css.flowIcon}/>
                        <div className={css.flowItem}>
                            <div className={css.iconIndex}>
                                <div className={css.icon}/>
                            </div>
                            <div className={css.flowTitle}>5.安排日程</div>
                        </div>
                    </div>

                </Box>
                {/*<Box title={'专家风采'} style={{background: "url(" + expertBanner01 + ")", backgroundSize: '100% 100%'}}>*/}
                {/*    { !isLoading &&  <ObjBox data={data.experts} type={1}/> }*/}
                {/*    <div className={css.moreBtn} onClick={()=>push('/expert/list')}>查看更多</div>*/}
                {/*</Box>*/}

                <Box title={'专家咨询'}  style={{background: "#F5F5F5"}} >
                    <div className={css.expertMeet}>
                        <img src={icon01} alt="" className={css.icon01}/>
                        <img src={icon02} alt="" className={css.icon02}/>
                        <div className={classNames(css.item,css.item01)}>
                            <div className={css.title}>服务咨询</div>
                            <div className={css.subtitle}>专家为您答疑解忧可选择远程交流或线下约见</div>
                        </div>
                        <div className={classNames(css.item,css.item02)}>
                            <div className={css.title}>行业匹配</div>
                            <div className={css.subtitle}>致力于为您匹配撮合专家顾问，打造属于企业的外部智囊团</div>
                        </div>
                        <div className={classNames(css.item,css.item03)}>
                            <div className={css.title}>专业评审</div>
                            <div className={css.subtitle}>为企业解决方案、招投标项目及可靠性验证提供盲选评审服务</div>
                        </div>
                    </div>

                </Box>

                <div className={css.contact}>
                    <div className={'container'} >
                        <div className={css.contactTitle}>
                            留下您的基本信息，了解更多
                        </div>
                        <Form form={ideaForm} className={css.form}>
                            <div className={css.formLeft}>
                                <Form.Item
                                    label="姓名"
                                    name="name"
                                    rules={[{required: true, message: '请输入您的姓名' }]}
                                >
                                    <Input autoComplete="off" placeholder="请输入您的姓名"/>
                                </Form.Item>
                                <Form.Item
                                    label="电话"
                                    name="mobile"
                                    rules={[{required: true, message: '请输入您的电话' }]}
                                >
                                    <Input autoComplete="off" placeholder="请输入您的电话"/>
                                </Form.Item>
                                <Form.Item label={'城市'} name={'area'}
                                           rules={[{required: true, message: '请选择所在城市',},({ getFieldValue }) => ({
                                               validator(rule, value) {
                                                   if(value && value[3]){
                                                       return Promise.reject('请选择所在城市');
                                                   }
                                                   return Promise.resolve();
                                               },
                                           })]}>
                                    <AddressItem size="middle"/>
                                </Form.Item>
                            </div>
                            <div className={css.formRight}>
                                <Form.Item
                                    label="咨询详情"
                                    name="content"
                                    extra={'注：限500字以内，我们将免费为您解答。'}
                                    rules={[{required: true, message: '请输入您要咨询的问题' }]}
                                >
                                    <Input.TextArea autoSize={{minRows:6}} autoComplete="off" placeholder="请输入您要咨询的问题"/>
                                </Form.Item>
                            </div>

                        </Form>
                        <div className={css.btn} onClick={saveIdea}>立即咨询 </div>
                    </div>
                </div>

            </div>
        </div>
    );
}